import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Grid,
  TextField,
  makeStyles,
  Paper, Typography,Box
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import GetAppIcon from '@material-ui/icons/GetApp';
import ClearIcon from '@material-ui/icons/Clear';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import MenuComponent from '../components/menu/menu-component';
import BlueButton from '../components/button/BlueButton';
import documentoService from '../Services/documentos/documentos-service';
import authService from '../Services/auth/auth-service';
import CustomAlert from '../components/alert/CustomAlert';
import InputAdornment from '@mui/material/InputAdornment';

import { useMediaQuery, useTheme } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  searchIcon: {
    marginRight: 4,
  },
  searchInput: {
    flex: 1,
    padding: '8px',
    fontSize: 18,
    width: '100%',
  },
  search: {
    marginBottom: 40,
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  paper: {
    borderRadius: 10,
    display: 'flex',
    padding: '15px 20px 15px',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px'
  },
  mensagemStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
  },
  mensagemDetalhe: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '13px',
    textAlign: 'center',
    padding: '0 0px',
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px'
  },
  infos: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },

  item: {
    marginBottom: 30,
  },
  avatar: {
    padding: 6,
    borderRadius: 50,
    backgroundColor: theme.palette.grey[500],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  categoryTitle: {
    marginTop: 20,
    marginBottom: 10,
    fontWeight: 'bold',
  },
}));

const categorizeDocuments = (documentos) => {
  const categories = {
    'DAF': [],
    'TVC': [],
    '5ª Roda': [],
    'Plataforma': [],
    'TCM': [],
    'Linker': [],
    'Nobreak': [],
    'Omnitelemetria': [],
    'Sensor': [],
    'Telemetria': [],
    'Ford Cargo': [],
    'Mercedes Benz': [],
    'Iveco Stralis': [],
    'Renault Master': [],
    'Scania R480': [],
    'Sprinter': [],
    'Bloqueio': [],
    'Suntech': [],
    'Teltonika': [],
    'Fiat Ducato': [],
    'Fiat Fiorino': [],
    'Renault KANGOO': [],
    'Scania R500': [],
    'Citroen': [],
    'Internacional': [],
    'Montana': [],
    'Trava Motorizada': [],
    'BYD': [],
    'Foton': [],
    'Padrão Chicote': [],
    'Carreta': [],
    'MDVR': [],
    '4450 ME': [],
    'Travas': [],
    'Hyundai': [],
    'Cofre': [],
    'Baú cliente': [],
    'Tacógrafo': [],
    'Traffilog': [],
    'Volvo': [],
    'CG 160': [],
    'LWART': [],
    'Conjunto Bi': [],
    'Constellation': [],
    'KIA': [],
    'Rastreador': [],
    'Scania': [],
    'SHACMAN': [],
    'Volkswagem': [],
    'Sinotruck': [],
    'Outros': []
  };

  documentos.forEach((doc) => {
    const title = doc.txtTitulo.toLowerCase();

    switch (true) {
      case title.includes('daf'):
        categories.DAF.push(doc);
        break;
      case title.includes('tvc'):
        categories.TVC.push(doc);
        break;
      case title.includes('5ªroda'):
        categories['5ª Roda'].push(doc);
        break;
      case title.includes('plataforma'):
        categories.Plataforma.push(doc);
        break;
      case title.includes('tcm'):
        categories.TCM.push(doc);
        break;
      case title.includes('linker'):
        categories.Linker.push(doc);
        break;
      case title.includes('nobreak'):
        categories.Nobreak.push(doc);
        break;
      case title.includes('omnitelemetria'):
        categories.Omnitelemetria.push(doc);
        break;
      case title.includes('sensor'):
        categories.Sensor.push(doc);
        break;
      case title.includes('telemetria'):
        categories.Telemetria.push(doc);
        break;
      case title.includes('ford cargo'):
        categories['Ford Cargo'].push(doc);
        break;
      case title.includes('mercedes benz') || title.includes('mercedes bens') || title.includes('mercedes'):
        categories['Mercedes Benz'].push(doc);
        break;
      case title.includes('iveco stralis') || title.includes('iveco'):
        categories['Iveco Stralis'].push(doc);
        break;
      case title.includes('renault master'):
        categories['Renault Master'].push(doc);
        break;
      case title.includes('scania r480'):
        categories['Scania R480'].push(doc);
        break;
      case title.includes('sprinter'):
        categories.Sprinter.push(doc);
        break;
      case title.includes('bloqueio'):
        categories.Bloqueio.push(doc);
        break;
      case title.includes('volkswagen delivery') || title.includes('volkswagem') || title.includes('vw') || title.includes('volks') || title.includes('volkswage'):
        categories['Volkswagem'].push(doc);
        break;
      case title.includes('suntech'):
        categories.Suntech.push(doc);
        break;
      case title.includes('teltonika') || title.includes('teltonica'):
        categories.Teltonika.push(doc);
        break;
      case title.includes('fiat ducato'):
        categories['Fiat Ducato'].push(doc);
        break;
      case title.includes('fiat fiorino'):
        categories['Fiat Fiorino'].push(doc);
        break;
      case title.includes('renault kangoo'):
        categories['Renault KANGOO'].push(doc);
        break;
      case title.includes('scania r500'):
        categories['Scania R500'].push(doc);
        break;
      case title.includes('citroen') || title.includes('citroën'):
        categories.Citroen.push(doc);
        break;
      case title.includes('internacional'):
        categories.Internacional.push(doc);
        break;
      case title.includes('montana'):
        categories.Montana.push(doc);
        break;
      case title.includes('trava motorizada'):
        categories['Trava Motorizada'].push(doc);
        break;
      case title.includes('byd'):
        categories.BYD.push(doc);
        break;
      case title.includes('foton'):
        categories.Foton.push(doc);
        break;
      case title.includes('padrão chicote'):
        categories['Padrão Chicote'].push(doc);
        break;
      case title.includes('carreta'):
        categories.Carreta.push(doc);
        break;
      case title.includes('mdvr'):
        categories.MDVR.push(doc);
        break;
      case title.includes('4450 me'):
        categories['4450 ME'].push(doc);
        break;
      case title.includes('travas'):
        categories.Travas.push(doc);
        break;
      case title.includes('hyundai') || title.includes('hyundai hr'):
        categories.Hyundai.push(doc);
        break;
      case title.includes('cofre'):
        categories.Cofre.push(doc);
        break;
      case title.includes('baú cliente'):
        categories['Baú cliente'].push(doc);
        break;
      case title.includes('tacógrafo'):
        categories.Tacógrafo.push(doc);
        break;
      case title.includes('traffilog'):
        categories.Traffilog.push(doc);
        break;
      case title.includes('volvo'):
        categories.Volvo.push(doc);
        break;
      case title.includes('cg 160'):
        categories['CG 160'].push(doc);
        break;
      case title.includes('lwart'):
        categories.LWART.push(doc);
        break;
      case title.includes('conjunto bi'):
        categories['Conjunto Bi'].push(doc);
        break;
      case title.includes('constellation'):
        categories.Constellation.push(doc);
        break;
      case title.includes('kia'):
        categories.KIA.push(doc);
        break;
      case title.includes('rastreador'):
        categories.Rastreador.push(doc);
        break;
      case title.includes('scania'):
        categories.Scania.push(doc);
        break;
      case title.includes('shacman'):
        categories.SHACMAN.push(doc);
        break;
      case title.includes('sinotruck'):
        categories.Sinotruck.push(doc);
        break;
      default:
        categories.Outros.push(doc);
        break;
    }
  });

  Object.keys(categories).forEach((key) => {
    if (categories[key].length === 0) {
      delete categories[key];
    }
  });

  return categories;
};

export default function DocumentosPage(props) {
  const classes = useStyles();
  const [documentos, setDocumentos] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [permissaoTCL, setPermissaoTCL] = useState(0);
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [tipoAlert, setTipoAlert] = useState('error');
  const [efeitoCarregando, setEfeitoCarregando] = useState({});

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    buscarDocumentos();
    setPermissaoTCL(authService.getUserInfo().usuario.classificacaoPrestador);
  }, []);

  const buscarDocumentos = async () => {
    setLoading(true);
    let DocumentosLista = await documentoService.listadocumentos();
    if (DocumentosLista) {
      let _Documentoslista = [];

      DocumentosLista.map((m) => {
        _Documentoslista.push({
          id: m.id || '',
          txtTitulo: m.txtTitulo || '',
          icone: m.icone || '',
          mimeArquivo: m.mimeArquivo,
          extencaoArquivo: m.extencaoArquivo,
        });
      });
      setDocumentos(_Documentoslista);
    }
    setLoading(false);
  };
  const handleClearSearch = () => {
    setSearch('');
  };

  const handleBaixarArquivo = async (id, titulo) => {
    setEfeitoCarregando((prev) => ({ ...prev, [id]: true }));

    let arquivo = await documentoService.arquivodocumentos(id);

    if (!arquivo.error) {
      // Crie um Blob a partir dos dados do arquivo
      const blob = new Blob([arquivo], { type: 'application/pdf' });

      // Crie um URL do Blob
      const fileURL = window.URL.createObjectURL(blob);

      // Crie um link temporário para download
      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', `${titulo}.pdf`);
      tempLink.click();
      setEfeitoCarregando((prev) => ({ ...prev, [id]: false }));
    } else {
      setMensagemAlert({ title: 'Erro!', body: 'Não foi possível baixar o arquivo!' });
      setTipoAlert('error');
      setAbrirAlert(true);
      setEfeitoCarregando((prev) => ({ ...prev, [id]: false }));
    }
  };
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  const filteredDocumentos = documentos.filter((doc) => {
    return doc.txtTitulo.toLowerCase().includes(search.toLowerCase());
  });

  const categorizedDocumentos = categorizeDocuments(filteredDocumentos);

  return (
    <>
      <MenuComponent />
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />
      {permissaoTCL !== 2 ? (
        <div style={{ margin: 30 }}>
          <h3 className={classes.tituloStyle}>Manuais</h3>
          <h4 className={classes.subtituloStyle}>
            <span
              onClick={() => {
                const userInfo = authService.getUserInfo();
                if (userInfo.usuario.gestor) {
                  window.location.assign('/gestor');
                } else {
                  window.location.assign('/agenda');
                }
              }}
              className={classes.linkStyle}
              style={{ cursor: 'pointer' }}
            >
              Home
            </span> {'>'} Manuais
          </h4>

          

          <Box display="flex" flexDirection="column" marginBottom={1}>
             <Grid container justifyContent="flex-end">
              <Grid item xs={isMobile ? 12 : 5}>
                    <TextField
                      variant="outlined"
                      placeholder="Pesquisar"
                      value={search}
                      onChange={handleSearchChange}
                      className={classes.searchInput}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {search ? (
                              <ClearIcon
                                style={{ cursor: 'pointer' }}
                                onClick={handleClearSearch}
                              />
                            ) : (
                              <SearchIcon className={classes.searchIcon} />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                </Grid>
              </Grid>
            </Box>
          <Paper className={classes.paper}>
          {loading ? (
              <span className={classes.infos}>
                <CircularProgress />
              </span>
              ) : (
                <>
                {Object.keys(categorizedDocumentos).length === 0 ? (
                  <Grid container justifyContent="center" alignItems="center" style={{ height: '100px' }}>
                  <Typography variant="subtitle1" color="textSecondary">
                    Não há Manuais.
                  </Typography>
                </Grid>
                ) : (
                  Object.keys(categorizedDocumentos).map((category) => (
                    <div key={category} >
                      <h2 className={classes.categoryTitle}>{category}</h2>
                      <Grid container>
                        {categorizedDocumentos[category].map((d) => (
                          <Grid
                            container
                            spacing={2}
                            key={d.id}
                            className={classes.item}
                          >
                            <Grid item xs={12} md={10} className={classes.row}>
                              <span className={classes.avatar}>
                                <PictureAsPdfIcon style={{ color: '#fff' }} />
                              </span>
                              <span style={{ flex: 1 }}>{d.txtTitulo}</span>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <BlueButton
                                fullWidth
                                onClick={() => handleBaixarArquivo(d.id, d.txtTitulo)}
                                variant="contained"
                                color="primary"
                                disabled={efeitoCarregando[d.id]}
                                startIcon={
                                  efeitoCarregando[d.id] ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <GetAppIcon />
                                  )
                                }
                              >
                                {efeitoCarregando[d.id] ? 'Baixando...' : 'Baixar'}
                              </BlueButton>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  ))
                )}
              </>
            )}
          </Paper>
        </div>
      ) : (
        <div style={{ margin: 30 }}>
          <h3 className={classes.tituloStyle}>Manuais</h3>
          <h4 className={classes.subtituloStyle}>
            <span
              onClick={() => {
                const userInfo = authService.getUserInfo();
                if (userInfo.usuario.gestor) {
                  window.location.assign('/gestor');
                } else {
                  window.location.assign('/agenda');
                }
              }}
              className={classes.linkStyle}
              style={{ cursor: 'pointer' }}
            >
              Home
            </span> {'>'} Manuais
          </h4>

          <Paper className={classes.paper}>
            <Grid container justifyContent="center">
              <h2 className={classes.mensagemStyle}>Você não tem permissão para acessar esta página!</h2>
            </Grid>
          </Paper>
        </div>
      )}
    </>
  );
}