import React, { useState } from 'react';
import ParticleAnimation from 'react-particle-animation';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logoOmnilink from '../../src/assets/img/logoOmni.png';
import BlueButton from '../components/button/BlueButton';
import BlueTextField from '../components/textfield/BlueTextField';
import { useHistory } from 'react-router-dom';
import FacebookCircularProgress from '../components/progress/CircularProgressBlue';
import ConsultaperfilService from '../Services/consultaperfil/consultaperfil-service';
import { ArrowBackIos } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import CustomAlert from '../components/alert/CustomAlert';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: 55
  },
}));

const emailValido = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

export default function RecuperarSenha(props) {
  const history = useHistory();
  const [email, setEmail] = React.useState('');
  const [botaoCarregando, setBotaoCarregando] = useState(false);
  const [bloquearBotao, setBloquearBotao] = useState(false);
  const [erroEmailEnviado, setErroEmailEnviado] = React.useState(false);
  const [emailEnviado, setEmailEnviado] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ title: '', body: '' });
  const [alertType, setAlertType] = useState('success');
  
  const recuperarSenha = async () => {
    setBotaoCarregando(true);

    let Consultass = await ConsultaperfilService.RecuperarSenha(JSON.stringify({ Email: email }));

    if (Consultass) {
      setAlertMessage({ title: 'Sucesso', body: 'E-mail de recuperação de senha enviado com sucesso!' });
      setAlertType('success');
      setAlertOpen(true);
      setEmailEnviado(true);
      setBotaoCarregando(false);
      setBloquearBotao(true);
      setTimeout(() => {
        history.push('/');
      }, 5000);
    } else {
      setAlertMessage({ title: 'Atenção!', body: 'Não foi possível enviar e-mail de recuperação de senha, verifique se seu e-mail está correto!' });
      setAlertType('fail');
      setAlertOpen(true);
      setErroEmailEnviado(true);
      setBotaoCarregando(false);
    }
  };
  const handleClose = () => {
    setErroEmailEnviado(false);
  };

  const handleBack = () => {
    window.location.href = '/';
  };

  const classes = useStyles();

  return (
    <div style={{ overflow: 'hidden', position: 'absolute', width: '100%', height: '100%' }}>
      <ParticleAnimation
        numParticles={110}
        interactive={true}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
        particleRadius={2.5}
        particleSpeed={1}
        background={{ r: 32, g: 155, b: 222, a: 255 }}
        color={{ r: 255, g: 255, b: 255, a: 255 }}
      />

      <Container
        style={{ backgroundColor: '#ffffff', position: 'relative', borderRadius: 10, marginTop: 75 }}
        component="main"
        maxWidth="xs"
      >
        <Grid className={classes.actions} container alignItems="center" justifyContent="flex-start">
          <Grid item xs={3} sm={2} md={1}>
            <Button
              style={{ color: '#209BDE', marginTop: '10px' }}
              onClick={handleBack}
              startIcon={<ArrowBackIos />}
            >
              Voltar
            </Button>
          </Grid>
        </Grid>

        <CssBaseline />
        <div className={classes.paper}>
          <img src={logoOmnilink} alt="Logo Omnilink" />
          <div className={classes.form}>
            <BlueTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Usuário"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              style={{ backgroundColor: 'white' }}
            />

            <CustomAlert
              open={alertOpen}
              message={alertMessage}
              type={alertType}
              onClose={() => setAlertOpen(false)}
            />
            {botaoCarregando ? (
              <div style={{ margin: 'auto', width: '20%' }}>
                <FacebookCircularProgress />
              </div>
            ) : (
              <BlueButton
                type="submit"
                fullWidth
                variant="contained"
                disabled={botaoCarregando || bloquearBotao || !emailValido(email)}
                onClick={() => recuperarSenha()}
                className={classes.submit}
              >
                Recuperar Senha
              </BlueButton>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
