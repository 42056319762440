import React from 'react'
import { InputLabel, Grid, FormControl, Select } from '@material-ui/core'
import MenuItem from '@mui/material/MenuItem'

export function VistoriaItems({
	vistoria,
	handleInputChange,
	classes,
	errors,
	disabledEditEntry,
	disabledEditExit
}) {
	return (
		<>
			<Grid container spacing={2} alignItems="center" style={{ marginBottom: 30 }}>
				<Grid item xs={12} sm={6} container>
					<div className={classes.subtitle}>Itens - Entrada / Saída</div>
				</Grid>
				{vistoria?.statusEntrada != 'Aceito' && (
					<Grid xs={12} sm={6} container spacing={1}>
						<Grid item xs={12}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="lan-tras" style={{ whiteSpace: 'nowrap' }}>
									Preenchimento Rápido
								</InputLabel>
								<Select
									labelId="marca-todos-itens"
									label="Preenchimento Rápido"
									disabled={disabledEditEntry}
									id="drpMarcaTodosItens"
									defaultValue=""
									onChange={(event) => {
										const selectedValue = event.target.value
										if (
											selectedValue === 'CF' ||
											selectedValue === 'NC' ||
											selectedValue === 'NP'
										) {
											handleInputChange('lanternas_traseiras', selectedValue)
											handleInputChange('lanternas_dianterias', selectedValue)
											handleInputChange('luz_freio', selectedValue)
											handleInputChange('luz_re', selectedValue)
											handleInputChange('pisca_alerta', selectedValue)
											handleInputChange('farol_baixo', selectedValue)
											handleInputChange('vidros_porta_traseira', selectedValue)
											handleInputChange('vidros_portas_dianteiras', selectedValue)
											handleInputChange('vidro_traseiro', selectedValue)
											handleInputChange('retrovisor', selectedValue)
											handleInputChange('bancos', selectedValue)
											handleInputChange('buzina', selectedValue)
											handleInputChange('cond_painel', selectedValue)
											handleInputChange('outros_equipamentos', selectedValue)
											handleInputChange('velocimetro', selectedValue)
											handleInputChange('computador_bordo', selectedValue)
											handleInputChange('limpador_dianteiro', selectedValue)
											handleInputChange('acendedor_cigarro', selectedValue)
											handleInputChange('sistema_som', selectedValue)
										}
									}}
								>
									<MenuItem value="" disabled>
										Selecione uma opção
									</MenuItem>
									<MenuItem value="CF">Selecionar tudo como conforme</MenuItem>
									<MenuItem value="NC">Selecionar tudo como não conforme</MenuItem>
									<MenuItem value="NP">Selecionar tudo como não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				)}
			</Grid>

			<Grid container spacing={2} alignItems="center">
				<Grid item xs={12} sm={6} container>
					<span className={classes.itemText}>
						Lanterna Traseira / Setas <span style={{ color: 'red' }}>*</span>
					</span>
				</Grid>
				<Grid xs={12} sm={6} container spacing={1}>
					<Grid item xs={vistoria?.entrada ? 6 : 12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="lan-tras">Lanterna Traseira / Setas</InputLabel>
							<Select
								labelId="lan-tras"
								label={<span>Lanterna Traseira / Setas</span>}
								disabled={disabledEditEntry}
								id="drpLanTraseira"
								value={vistoria?.lanternas_traseiras || ''}
								error={!!errors.lanternas_traseiras}
								helperText={errors.lanternas_traseiras?.message}
								onChange={(event) => handleInputChange('lanternas_traseiras', event.target.value)}
							>
								<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{vistoria?.entrada && (
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="lan-tras-saida">Saída</InputLabel>
								<Select
									labelId="lan-tras-saida"
									id="drpLanTraseiraSaida"
									label="Saída"
									disabled={disabledEditExit}
									value={vistoria?.lanternas_traseiras_saida || vistoria?.lanternas_traseiras}
									onChange={(event) =>
										handleInputChange('lanternas_traseiras_saida', event.target.value)
									}
								>
									<MenuItem value="" disabled>
										Selecione uma opção
									</MenuItem>
									<MenuItem value="CF">Conforme</MenuItem>
									<MenuItem value="NC">Não conforme</MenuItem>
									<MenuItem value="NP">Não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={2} alignItems="center" style={{ marginTop: 30 }}>
				<Grid item xs={12} sm={6} container>
					<span className={classes.itemText}>
						Lanterna Dianteira / Setas <span style={{ color: 'red' }}>*</span>
					</span>
				</Grid>
				<Grid xs={12} sm={6} container spacing={1}>
					<Grid item xs={vistoria?.entrada ? 6 : 12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="label_lanternas_dianterias">Lanterna Dianteira / Setas</InputLabel>
							<Select
								labelId="label_lanternas_dianterias"
								label="Lanterna Dianteira / Setas"
								disabled={disabledEditEntry}
								id="select_lanternas_dianterias"
								value={vistoria?.lanternas_dianterias || ''}
								error={!!errors.lanternas_dianterias}
								helperText={errors.lanternas_dianterias?.message}
								onChange={(event) => handleInputChange('lanternas_dianterias', event.target.value)}
							>
								<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{vistoria?.entrada && (
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="label_lanternas_dianterias_saida">Saída</InputLabel>
								<Select
									labelId="label_lanternas_dianterias_saida"
									id="select_lanternas_dianterias_saida"
									label="Saída"
									z
									disabled={disabledEditExit}
									value={vistoria?.lanternas_dianterias_saida || vistoria?.lanternas_dianterias}
									onChange={(event) =>
										handleInputChange('lanternas_dianterias_saida', event.target.value)
									}
								>
									<MenuItem value="" disabled>
										Selecione uma opção
									</MenuItem>
									<MenuItem value="CF">Conforme</MenuItem>
									<MenuItem value="NC">Não conforme</MenuItem>
									<MenuItem value="NP">Não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={2} alignItems="center" style={{ marginTop: 30 }}>
				<Grid item xs={12} sm={6} container>
					<span className={classes.itemText}>
						Luz de Freio / Brake Light <span style={{ color: 'red' }}>*</span>
					</span>
				</Grid>
				<Grid xs={12} sm={6} container spacing={1}>
					<Grid item xs={vistoria?.entrada ? 6 : 12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="label_luz_freio">Luz de Freio / Brake Light</InputLabel>
							<Select
								labelId="label_luz_freio"
								label="Luz de Freio / Brake Light"
								disabled={disabledEditEntry}
								id="select_luz_freio"
								value={vistoria?.luz_freio || ''}
								error={!!errors.luz_freio}
								helperText={errors.luz_freio?.message}
								onChange={(event) => handleInputChange('luz_freio', event.target.value)}
							>
								<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{vistoria?.entrada && (
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="label_luz_freio_saida">Saída</InputLabel>
								<Select
									labelId="label_luz_freio_saida"
									id="select_luz_freio_saida"
									label="Saída"
									disabled={disabledEditExit}
									value={vistoria?.luz_freio_saida || vistoria?.luz_freio}
									onChange={(event) => handleInputChange('luz_freio_saida', event.target.value)}
								>
									<MenuItem value="" disabled>
										Selecione uma opção
									</MenuItem>
									<MenuItem value="CF">Conforme</MenuItem>
									<MenuItem value="NC">Não conforme</MenuItem>
									<MenuItem value="NP">Não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={2} alignItems="center" style={{ marginTop: 30 }}>
				<Grid item xs={12} sm={6} container>
					<span className={classes.itemText}>
						Luz de Ré <span style={{ color: 'red' }}>*</span>
					</span>
				</Grid>
				<Grid xs={12} sm={6} container spacing={1}>
					<Grid item xs={vistoria?.entrada ? 6 : 12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="label_luz_re">Luz de Ré</InputLabel>
							<Select
								labelId="label_luz_re"
								label="Luz de Ré"
								disabled={disabledEditEntry}
								id="select_luz_re"
								value={vistoria?.luz_re || ''}
								error={!!errors.luz_re}
								helperText={errors.luz_re?.message}
								onChange={(event) => handleInputChange('luz_re', event.target.value)}
							>
							<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{vistoria?.entrada && (
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="label_luz_re_saida">Saída</InputLabel>
								<Select
									labelId="label_luz_re_saida"
									id="select_luz_re_saida"
									label="Saída"
									disabled={disabledEditExit}
									value={vistoria?.luz_re_saida || vistoria?.luz_re}
									onChange={(event) => handleInputChange('luz_re_saida', event.target.value)}
								>
									<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={2} alignItems="center" style={{ marginTop: 30 }}>
				<Grid item xs={12} sm={6} container>
					<span className={classes.itemText}>
						Pisca Alerta <span style={{ color: 'red' }}>*</span>
					</span>
				</Grid>
				<Grid xs={12} sm={6} container spacing={1}>
					<Grid item xs={vistoria?.entrada ? 6 : 12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="label_pisca_alerta">Pisca Alerta</InputLabel>
							<Select
								labelId="label_pisca_alerta"
								label="Pisca Alerta"
								disabled={disabledEditEntry}
								id="select_pisca_alerta"
								value={vistoria?.pisca_alerta || ''}
								error={!!errors.pisca_alerta}
								helperText={errors.pisca_alerta?.message}
								onChange={(event) => handleInputChange('pisca_alerta', event.target.value)}
							>
								<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{vistoria?.entrada && (
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="label_pisca_alerta_saida">Saída</InputLabel>
								<Select
									labelId="label_pisca_alerta_saida"
									id="select_pisca_alerta_saida"
									label="Saída"
									disabled={disabledEditExit}
									value={vistoria?.pisca_alerta_saida || vistoria?.pisca_alerta}
									onChange={(event) => handleInputChange('pisca_alerta_saida', event.target.value)}
								>
									<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>

			<div className="html2pdf__page-break"></div>

			<Grid container spacing={2} alignItems="center" style={{ marginTop: 30 }}>
				<Grid item xs={12} sm={6} container>
					<span className={classes.itemText}>
						Farois Alto e Baixo (e lampejo) <span style={{ color: 'red' }}>*</span>
					</span>
				</Grid>
				<Grid xs={12} sm={6} container spacing={1}>
					<Grid item xs={vistoria?.entrada ? 6 : 12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="label_farol_baixo">Farois Alto e Baixo (e lampejo)</InputLabel>
							<Select
								labelId="label_farol_baixo"
								label="Farois Alto e Baixo (e lampejo)"
								disabled={disabledEditEntry}
								id="select_farol_baixo"
								value={vistoria?.farol_baixo || ''}
								error={!!errors.farol_baixo}
								helperText={errors.farol_baixo?.message}
								onChange={(event) => handleInputChange('farol_baixo', event.target.value)}
							>
							<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{vistoria?.entrada && (
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="label_farol_baixo_saida">Saída</InputLabel>
								<Select
									labelId="label_farol_baixo_saida"
									id="select_farol_baixo_saida"
									label="Saída"
									disabled={disabledEditExit}
									value={vistoria?.farol_baixo_saida || vistoria?.farol_baixo}
									onChange={(event) => handleInputChange('farol_baixo_saida', event.target.value)}
								>
								<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={2} alignItems="center" style={{ marginTop: 30 }}>
				<Grid item xs={12} sm={6} container>
					<span className={classes.itemText}>
						Vidros Portas Tras. <span style={{ color: 'red' }}>*</span>
					</span>
				</Grid>
				<Grid xs={12} sm={6} container spacing={1}>
					<Grid item xs={vistoria?.entrada ? 6 : 12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="label_vidros_porta_traseira">Vidros Portas Tras.</InputLabel>
							<Select
								labelId="label_vidros_porta_traseira"
								label="Vidros Portas Tras."
								disabled={disabledEditEntry}
								id="select_vidros_porta_traseira"
								value={vistoria?.vidros_porta_traseira || ''}
								error={!!errors.vidros_porta_traseira}
								helperText={errors.vidros_porta_traseira?.message}
								onChange={(event) => handleInputChange('vidros_porta_traseira', event.target.value)}
							>
								<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{vistoria?.entrada && (
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="label_vidros_porta_traseira_saida">Saída</InputLabel>
								<Select
									labelId="label_vidros_porta_traseira_saida"
									id="select_vidros_porta_traseira_saida"
									label="Saída"
									disabled={disabledEditExit}
									value={vistoria?.vidros_porta_traseira_saida || vistoria?.vidros_porta_traseira}
									onChange={(event) =>
										handleInputChange('vidros_porta_traseira_saida', event.target.value)
									}
								>
									<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={2} alignItems="center" style={{ marginTop: 30 }}>
				<Grid item xs={12} sm={6} container>
					<span className={classes.itemText}>
						Vidros Portas Diant. <span style={{ color: 'red' }}>*</span>
					</span>
				</Grid>
				<Grid xs={12} sm={6} container spacing={1}>
					<Grid item xs={vistoria?.entrada ? 6 : 12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="label_vidros_portas_dianteiras">Vidros Portas Diant.</InputLabel>
							<Select
								labelId="label_vidros_portas_dianteiras"
								label="Vidros Portas Diant."
								disabled={disabledEditEntry}
								id="select_vidros_portas_dianteiras"
								value={vistoria?.vidros_portas_dianteiras || ''}
								error={!!errors.vidros_portas_dianteiras}
								helperText={errors.vidros_portas_dianteiras?.message}
								onChange={(event) =>
									handleInputChange('vidros_portas_dianteiras', event.target.value)
								}
							>
								<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{vistoria?.entrada && (
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="label_vidros_portas_dianteiras_saidas">Saída</InputLabel>
								<Select
									labelId="label_vidros_portas_dianteiras_saidas"
									id="select_vidros_portas_dianteiras_saidas"
									label="Saída"
									disabled={disabledEditExit}
									value={
										vistoria?.vidros_portas_dianteiras_saidas || vistoria?.vidros_portas_dianteiras
									}
									onChange={(event) =>
										handleInputChange('vidros_portas_dianteiras_saidas', event.target.value)
									}
								>
									<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={2} alignItems="center" style={{ marginTop: 30 }}>
				<Grid item xs={12} sm={6} container>
					<span className={classes.itemText}>
						Vidro Traseiro <span style={{ color: 'red' }}>*</span>
					</span>
				</Grid>
				<Grid xs={12} sm={6} container spacing={1}>
					<Grid item xs={vistoria?.entrada ? 6 : 12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="label_vidro_traseiro">Vidro Traseiro </InputLabel>
							<Select
								labelId="label_vidro_traseiro"
								label="Vidro Traseiro "
								disabled={disabledEditEntry}
								id="select_vidro_traseiro"
								value={vistoria?.vidro_traseiro || ''}
								error={!!errors.vidro_traseiro}
								helperText={errors.vidro_traseiro?.message}
								onChange={(event) => handleInputChange('vidro_traseiro', event.target.value)}
							>
								<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{vistoria?.entrada && (
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="label_vidro_traseiro_saida">Saída</InputLabel>
								<Select
									labelId="label_vidro_traseiro_saida"
									id="select_vidro_traseiro_saida"
									label="Saída"
									disabled={disabledEditExit}
									value={vistoria?.vidro_traseiro_saida || vistoria?.vidro_traseiro}
									onChange={(event) =>
										handleInputChange('vidro_traseiro_saida', event.target.value)
									}
								>
									<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={2} alignItems="center" style={{ marginTop: 30 }}>
				<Grid item xs={12} sm={6} container>
					<span className={classes.itemText}>
						Retrovisor Motorista e Carona <span style={{ color: 'red' }}>*</span>
					</span>
				</Grid>
				<Grid xs={12} sm={6} container spacing={1}>
					<Grid item xs={vistoria?.entrada ? 6 : 12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="label_retrovisor">Retrovisor Motorista e Carona</InputLabel>
							<Select
								labelId="label_retrovisor"
								label="Retrovisor Motorista e Carona"
								disabled={disabledEditEntry}
								id="select_retrovisor"
								value={vistoria?.retrovisor || ''}
								error={!!errors.retrovisor}
								helperText={errors.retrovisor?.message}
								onChange={(event) => handleInputChange('retrovisor', event.target.value)}
							>
								<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{vistoria?.entrada && (
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="label_retrovisor_saida">Saída</InputLabel>
								<Select
									labelId="label_retrovisor_saida"
									id="select_retrovisor_saida"
									label="Saída"
									disabled={disabledEditExit}
									value={vistoria?.retrovisor_saida || vistoria?.retrovisor}
									onChange={(event) => handleInputChange('retrovisor_saida', event.target.value)}
								>
									<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={2} alignItems="center" style={{ marginTop: 30 }}>
				<Grid item xs={12} sm={6} container>
					<span className={classes.itemText}>
						Bancos, Estofados e Tapetes <span style={{ color: 'red' }}>*</span>
					</span>
				</Grid>
				<Grid xs={12} sm={6} container spacing={1}>
					<Grid item xs={vistoria?.entrada ? 6 : 12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="label_bancos">Bancos, Estofados e Tapetes</InputLabel>
							<Select
								labelId="label_bancos"
								label="Bancos, Estofados e Tapetes"
								disabled={disabledEditEntry}
								id="select_bancos"
								value={vistoria?.bancos || ''}
								error={!!errors.bancos}
								helperText={errors.bancos?.message}
								onChange={(event) => handleInputChange('bancos', event.target.value)}
							>
								<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{vistoria?.entrada && (
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="label_bancos_saida">Saída</InputLabel>
								<Select
									labelId="label_bancos_saida"
									id="select_bancos_saida"
									label="Saída"
									disabled={disabledEditExit}
									value={vistoria?.bancos_saida || vistoria?.bancos}
									onChange={(event) => handleInputChange('bancos_saida', event.target.value)}
								>
									<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={2} alignItems="center" style={{ marginTop: 30 }}>
				<Grid item xs={12} sm={6} container>
					<span className={classes.itemText}>
						Buzina <span style={{ color: 'red' }}>*</span>
					</span>
				</Grid>
				<Grid xs={12} sm={6} container spacing={1}>
					<Grid item xs={vistoria?.entrada ? 6 : 12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="label_buzina">Buzina</InputLabel>
							<Select
								labelId="label_buzina"
								label="Buzina"
								disabled={disabledEditEntry}
								id="select_buzina"
								value={vistoria?.buzina || ''}
								error={!!errors.buzina}
								helperText={errors.buzina?.message}
								onChange={(event) => handleInputChange('buzina', event.target.value)}
							>
								<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{vistoria?.entrada && (
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="label_buzina_saida">Saída</InputLabel>
								<Select
									labelId="label_buzina_saida"
									id="select_buzina_saida"
									label="Saída"
									disabled={disabledEditExit}
									value={vistoria?.buzina_saida || vistoria?.buzina}
									onChange={(event) => handleInputChange('buzina_saida', event.target.value)}
								>
									<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={2} alignItems="center" style={{ marginTop: 30 }}>
				<Grid item xs={12} sm={6} container>
					<span className={classes.itemText}>
						Condições do Painel <span style={{ color: 'red' }}>*</span>
					</span>
				</Grid>
				<Grid xs={12} sm={6} container spacing={1}>
					<Grid item xs={vistoria?.entrada ? 6 : 12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="label_cond_painel">Condições do Painel</InputLabel>
							<Select
								labelId="label_cond_painel"
								label="Condições do Painel"
								disabled={disabledEditEntry}
								id="select_cond_painel"
								value={vistoria?.cond_painel || ''}
								error={!!errors.cond_painel}
								helperText={errors.cond_painel?.message}
								onChange={(event) => handleInputChange('cond_painel', event.target.value)}
							>
								<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{vistoria?.entrada && (
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="label_cond_painel_saida">Saída</InputLabel>
								<Select
									labelId="label_cond_painel_saida"
									id="select_cond_painel_saida"
									label="Saída"
									disabled={disabledEditExit}
									value={vistoria?.cond_painel_saida || vistoria?.cond_painel}
									onChange={(event) => handleInputChange('cond_painel_saida', event.target.value)}
								>
									<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={2} alignItems="center" style={{ marginTop: 30 }}>
				<Grid item xs={12} sm={6} container>
					<span className={classes.itemText}>
						Outros Equipamentos <span style={{ color: 'red' }}>*</span>
					</span>
				</Grid>
				<Grid xs={12} sm={6} container spacing={1}>
					<Grid item xs={vistoria?.entrada ? 6 : 12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="label_outros_equipamentos">Outros Equipamentos</InputLabel>
							<Select
								labelId="label_outros_equipamentos"
								label="Outros Equipamentos"
								disabled={disabledEditEntry}
								id="select_outros_equipamentos"
								value={vistoria?.outros_equipamentos || ''}
								error={!!errors.outros_equipamentos}
								helperText={errors.outros_equipamentos?.message}
								onChange={(event) => handleInputChange('outros_equipamentos', event.target.value)}
							>
								<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{vistoria?.entrada && (
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="label_outros_equipamentos_saida">Saída</InputLabel>
								<Select
									labelId="label_outros_equipamentos_saida"
									id="select_outros_equipamentos_saida"
									label="Saída"
									disabled={disabledEditExit}
									value={vistoria?.outros_equipamentos_saida || vistoria?.outros_equipamentos}
									onChange={(event) =>
										handleInputChange('outros_equipamentos_saida', event.target.value)
									}
								>
									<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={2} alignItems="center" style={{ marginTop: 30 }}>
				<Grid item xs={12} sm={6} container>
					<span className={classes.itemText}>
						Velocímetro <span style={{ color: 'red' }}>*</span>
					</span>
				</Grid>
				<Grid xs={12} sm={6} container spacing={1}>
					<Grid item xs={vistoria?.entrada ? 6 : 12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="label_velocimetro">Velocímetro</InputLabel>
							<Select
								labelId="label_velocimetro"
								label="Velocímetro"
								disabled={disabledEditEntry}
								id="select_velocimetro"
								value={vistoria?.velocimetro || ''}
								error={!!errors.velocimetro}
								helperText={errors.velocimetro?.message}
								onChange={(event) => handleInputChange('velocimetro', event.target.value)}
							>
								<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{vistoria?.entrada && (
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="label_velocimetro_saida">Saída</InputLabel>
								<Select
									labelId="label_velocimetro_saida"
									id="select_velocimetro_saida"
									label="Saída"
									disabled={disabledEditExit}
									value={vistoria?.velocimetro_saida || vistoria?.velocimetro}
									onChange={(event) => handleInputChange('velocimetro_saida', event.target.value)}
								>
									<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={2} alignItems="center" style={{ marginTop: 30 }}>
				<Grid item xs={12} sm={6} container>
					<span className={classes.itemText}>
						Computador de Bordo <span style={{ color: 'red' }}>*</span>
					</span>
				</Grid>
				<Grid xs={12} sm={6} container spacing={1}>
					<Grid item xs={vistoria?.entrada ? 6 : 12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="label_computador_bordo">Computador de Bordo </InputLabel>
							<Select
								labelId="label_computador_bordo"
								label="Computador de Bordo "
								disabled={disabledEditEntry}
								id="select_computador_bordo"
								value={vistoria?.computador_bordo || ''}
								error={!!errors.computador_bordo}
								helperText={errors.computador_bordo?.message}
								onChange={(event) => handleInputChange('computador_bordo', event.target.value)}
							>
								<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{vistoria?.entrada && (
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="label_computador_bordo_saida">Saída</InputLabel>
								<Select
									labelId="label_computador_bordo_saida"
									id="select_computador_bordo_saida"
									label="Saída"
									disabled={disabledEditExit}
									value={vistoria?.computador_bordo_saida || vistoria?.computador_bordo}
									onChange={(event) =>
										handleInputChange('computador_bordo_saida', event.target.value)
									}
								>
									<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={2} alignItems="center" style={{ marginTop: 30 }}>
				<Grid item xs={12} sm={6} container>
					<span className={classes.itemText}>
						Limpador de Vidro Diant. e Tras. <span style={{ color: 'red' }}>*</span>
					</span>
				</Grid>
				<Grid xs={12} sm={6} container spacing={1}>
					<Grid item xs={vistoria?.entrada ? 6 : 12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="label_limpador_dianteiro">
								Limpador de Vidro Diant. e Tras.
							</InputLabel>
							<Select
								labelId="label_limpador_dianteiro"
								label="Limpador de Vidro Diant. e Tras."
								disabled={disabledEditEntry}
								id="select_limpador_dianteiro"
								value={vistoria?.limpador_dianteiro || ''}
								error={!!errors.limpador_dianteiro}
								helperText={errors.limpador_dianteiro?.message}
								onChange={(event) => handleInputChange('limpador_dianteiro', event.target.value)}
							>
								<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{vistoria?.entrada && (
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="label_limpador_dianteiro_saida">Saída</InputLabel>
								<Select
									labelId="label_limpador_dianteiro_saida"
									id="select_limpador_dianteiro_saida"
									label="Saída"
									disabled={disabledEditExit}
									value={vistoria?.limpador_dianteiro_saida || vistoria?.limpador_dianteiro}
									onChange={(event) =>
										handleInputChange('limpador_dianteiro_saida', event.target.value)
									}
								>
									<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={2} alignItems="center" style={{ marginTop: 30 }}>
				<Grid item xs={12} sm={6} container>
					<span className={classes.itemText}>
						Acendedor de Cigarro <span style={{ color: 'red' }}>*</span>
					</span>
				</Grid>
				<Grid xs={12} sm={6} container spacing={1}>
					<Grid item xs={vistoria?.entrada ? 6 : 12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="label_acendedor_cigarro">Acendedor de Cigarro</InputLabel>
							<Select
								labelId="label_acendedor_cigarro"
								label="Acendedor de Cigarro"
								disabled={disabledEditEntry}
								id="select_acendedor_cigarro"
								value={vistoria?.acendedor_cigarro || ''}
								error={!!errors.acendedor_cigarro}
								helperText={errors.acendedor_cigarro?.message}
								onChange={(event) => handleInputChange('acendedor_cigarro', event.target.value)}
							>
								<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{vistoria?.entrada && (
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="label_acendedor_cigarro_saida">Saída</InputLabel>
								<Select
									labelId="label_acendedor_cigarro_saida"
									id="select_acendedor_cigarro_saida"
									label="Saída"
									disabled={disabledEditExit}
									value={vistoria?.acendedor_cigarro_saida || vistoria?.acendedor_cigarro}
									onChange={(event) =>
										handleInputChange('acendedor_cigarro_saida', event.target.value)
									}
								>
									<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>

			<Grid container spacing={2} alignItems="center" style={{ marginTop: 30 }}>
				<Grid item xs={12} sm={6} container>
					<span className={classes.itemText}>
						Sistema de Som <span style={{ color: 'red' }}>*</span>
					</span>
				</Grid>
				<Grid xs={12} sm={6} container spacing={1}>
					<Grid item xs={vistoria?.entrada ? 6 : 12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="label_sistema_som">Sistema de Som</InputLabel>
							<Select
								labelId="label_sistema_som"
								label="Sistema de Som"
								disabled={disabledEditEntry}
								id="select_sistema_som"
								value={vistoria?.sistema_som || ''}
								error={!!errors.sistema_som}
								helperText={errors.sistema_som?.message}
								onChange={(event) => handleInputChange('sistema_som', event.target.value)}
							>
								<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					{vistoria?.entrada && (
						<Grid item xs={6}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="label_sistema_som_saida">Saída</InputLabel>
								<Select
									labelId="label_sistema_som_saida"
									id="select_sistema_som_saida"
									label="Saída"
									disabled={disabledEditExit}
									value={vistoria?.sistema_som_saida || vistoria?.sistema_som}
									onChange={(event) => handleInputChange('sistema_som_saida', event.target.value)}
								>
									<MenuItem value="" disabled>
									Selecione uma opção
								</MenuItem>
								<MenuItem value="CF">Conforme</MenuItem>
								<MenuItem value="NC">Não conforme</MenuItem>
								<MenuItem value="NP">Não possui</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
				</Grid>
			</Grid>
		</>
	)
}
