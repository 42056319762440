import config from '../../config';

const configAPIGateway = config.configAPIGateway();
const _configAPITecnico = config.configAPITecnico();

class AuthService {

  async tokenGateway() {
    try {
      let response = await fetch(`${configAPIGateway.URI_GATEWAY}/token?usuario=${configAPIGateway.API_USER}&senha=${configAPIGateway.API_PASS}`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
      });

      if (response.status === 200) return await response.json();
      return null;

    } catch (error) {
      console.log(error);
    }
  }

  async login(usuario) {
    try {
      let response = await fetch(`${_configAPITecnico.URI_API}/usuario/login`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: usuario
      });

      if (response.status === 200) return await response.json();
      return null;

    } catch (error) {
      console.log(error);
    }
  }

  logOut() {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('firstLoginUser');
    localStorage.removeItem('loginTime');
    return null;
  }

  getUserInfo() {
    return JSON.parse(localStorage.getItem('userInfo'));
  }

  async request(url, options = {}) {
    try {
      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${this.getToken()}`,
        },
      });
      
      console.log("Status da resposta:", response.status); // Log para verificar o status da resposta
  
      if (response.status === 401 || response.status === 404) {
        console.log("Erro 401 ou 404 detectado"); // Log para verificar se entrou na condição de erro
        this.handleAuthError();
        throw new Error(`Erro ${response.status}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error('Erro na requisição:', error);
      if (error.message === 'Erro 401' || error.message === 'Erro 404') {
        this.handleAuthError();
      }
      throw error;
    }
  }
  

  getToken() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    return userInfo ? userInfo.token : null;
  }

  handleAuthError() {
    this.logOut();
    window.dispatchEvent(new CustomEvent('openLoginModal'));
  }

  headerAPITecnico() {
    if (!localStorage.getItem('userInfo'))
      return {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      };

    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`
    };
  }
}

export default new AuthService();
