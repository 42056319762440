import React, { useEffect, useState } from 'react';
import {
  Paper, Grid, CircularProgress, Dialog,
  DialogTitle, DialogContent, DialogActions
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MenuComponent from '../components/menu/menu-component';
import BlueButton from '../components/button/BlueButton';
import TodayIcon from '@mui/icons-material/Today';
import ordemServicoServico from '../Services/OrdemServico/ordem-servico-servico';
import CloseIcon from '@material-ui/icons/Close';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormularioAtivacaoComponent from './component/formulario-ativacao-compoent';
import SensorService from '../Services/Sensores/sensores-service';
import BaseInstaladaComponent from './component/base-instalada-component';
import useForm from '../hooks/useForm';
import { CheckCircleOutline, HighlightOff, HourglassEmpty } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import authService from '../Services/auth/auth-service';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { OSVistoriaPage } from '../ordemServico/os-vistoria-page';
import { statusVistoria } from '../util/statusVistoria';
import vistoriaService from '../Services/Vistoria/vistoria-service';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import CustomAlert from '../components/alert/CustomAlert';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { useParams, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 80px 40px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px 20px',
    },
  },
  button: {
    marginTop: 10,
  },
  actions: {
    marginBottom: 6,
  },
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 10px',
    },
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px'
  },
  mensagemStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px'
  },
  title: {
    fontSize: 23,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 19,
    fontWeight: 'bold',
    marginTop: 40,
    marginBottom: 20,
  },
  expansionSubtitle: {
    fontSize: 23,
    fontWeight: 'bold',
  },
  itemText: {
    fontSize: 16,
  },
}));

export default function AtividadeServicoPage(props) {
  const classes = useStyles();
  const history = useHistory();
  const { na } = useParams();
  const [os, setOS] = useState(null);
  const [baseInstalada, setBaseInstalada] = useState(null);
  const [sensores, setSensores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [statusNa, setStatusNa] = useState('');
  const [openDialogVistoria, setOpenDialogVistoria] = useState(false);
  const [itensOS, setItensOS] = useState([]);
  const [dadosVistoria, setDadosVistoria] = useState(null);
  const [abrirConfirmacaoModal, setAbrirModalConfirmacao] = useState(false);
  const [efeitoCarregando, setEfeitoCarregando] = useState(false);
  const areaAtendimentoID = authService?.getUserInfo()?.usuario?.tz_area_atendimento_microsigaid;
  const classificacaoPrestador = authService?.getUserInfo()?.usuario?.classificacaoPrestador;
  const usuarioGestor = authService?.getUserInfo()?.usuario?.gestor;
  const [efeitoCarregandoSalvarImagens, setEfeitoCarregandoSalvarImagens] = useState(false);
  const [efeitoCarregandoBaixarImagem, setEfeitoCarregandoBaixarImagem] = useState(false);
  const [nomeFotoBaixando, setNomeFotoBaixando] = useState('');
  const [imagens, setImagens] = useState([]);
  const inputFileRefs = useRef({});
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ title: '', body: '' });
  const [alertType, setAlertType] = useState('success');
  const [fotosInput, setfotosInput] = useState({
    imagem: null,
    nome: null,
    nomeImagem: null,
  });
  const [loadVistoria, setLoadVistoria] = useState(false);
  const [mensagemAlertaAcessoURL, setMensagemAlertaAcessoURL] = useState(false);

  const handleBackOrdemServico = () => {
    history.push(`/ordemservico/${na}`);
  };

  const motivoCodigoMap = {
    "419400000": "Apagado",
    "419400001": "Display queimado",
    "419400002": "Teclas falhando",
    "419400003": "Não recebe comandos",
    "419400004": "Mau uso",
    "419400005": "Queimado",
    "419400006": "Infiltração d'água",
    "419400007": "Não comunica",
    "419400008": "Não aciona",
    "419400009": "Não bloqueia",
    "419400010": "Quebrado - Mau uso",
    "419400011": "Senha supervisor"
  };

  const mapCodigoToMotivo = (motivo) => motivoCodigoMap[motivo] || null;

  const tiposImagens = [
    {
      id: 'fotoFrontal',
      label: 'Foto Frontal',
      arquivo: 'foto_frontal'
    },
    {
      id: 'fotoOdometro',
      label: 'Foto Hodômetro',
      arquivo: 'foto_odometro'
    },
    {
      id: 'fotoLateral',
      label: 'Foto Lateral',
      arquivo: 'foto_lateral'
    },
    {
      id: 'fotoTrava',
      label: 'Foto da Trava',
      arquivo: 'foto_trava'
    },
    {
      id: 'fotoSensoresCabine',
      label: 'Sensores da Cabine',
      arquivo: 'foto_sensores_cabine'
    },
    {
      id: 'fotoSensoresBau',
      label: 'Sensores de Baú',
      arquivo: 'foto_sensores_bau'
    },
    {
      id: 'fotoLigacaoTelemetriaME',
      label: 'Ligação da Telemetria ME',
      arquivo: 'foto_ligacao_telemetria_me'
    },
    {
      id: 'fotoLigacaoCaboAlimentacao',
      label: 'Ligação Cabos Positivo e Negativo',
      arquivo: 'foto_ligacao_cabo_alimentacao'
    },
    {
      id: 'fotoIgnicao',
      label: 'Ignição',
      arquivo: 'foto_ignicao'
    },
    {
      id: 'fotoLigacaoBloqueio1',
      label: 'Ligação do Bloqueio 1',
      arquivo: 'foto_ligacao_bloqueio1'
    },
    {
      id: 'fotoMauUso',
      label: 'Foto de Mau Uso',
      arquivo: 'foto_mal_uso'
    },
  ]

  const tiposImagensOmnimob = [
    {
      id: 'fotoLocalAlojamentoOmnimob',
      label: 'Local de Alojamento Omnimob',
      arquivo: 'foto_local_alojamento_omnimob'
    },
    {
      id: 'fotoLigacaoCaboAlimentacao',
      label: 'Ligação Cabos Positivo e Negativo',
      arquivo: 'foto_ligacao_cabo_alimentacao'
    },
    {
      id: 'fotoLigacaoBloqueio1',
      label: 'Ligação do Bloqueio 1',
      arquivo: 'foto_ligacao_bloqueio1'
    },
    {
      id: 'fotoLigacaoBloqueio2',
      label: 'Ligação do Bloqueio 2',
      arquivo: 'foto_ligacao_bloqueio2'
    },
    {
      id: 'fotoLigacaoBloqueio3',
      label: 'Ligação do Bloqueio 3',
      arquivo: 'foto_ligacao_bloqueio3'
    },
    {
      id: 'fotoLigacaoBloqueio4',
      label: 'Ligação do Bloqueio 4',
      arquivo: 'foto_ligacao_bloqueio4'
    },
    {
      id: 'fotoLigacaoCabos485',
      label: 'Ligação dos Cabos 485',
      arquivo: 'foto_ligacao_cabos_485'
    },
    {
      id: 'fotoTecladoNumeroSerieOmnimob',
      label: 'Teclado com Nº de série Omnimob',
      arquivo: 'foto_teclado_numero_serie_omnimob'
    },
    {
      id: 'fotoPainelInstrumentosVeiculoBloqueio1e2',
      label: 'Painel Instrumentos Bloqueio 1 e 2',
      arquivo: 'foto_painel_instrumentos_veiculo_bloqueio1e2'
    },
    {
      id: 'fotoPainelInstrumentosVeiculoBloqueio3',
      label: 'Painel Instrumentos Bloqueio 3',
      arquivo: 'foto_painel_instrumentos_veiculo_bloqueio3'
    },
    {
      id: 'fotoPainelInstrumentosVeiculoBloqueio4',
      label: 'Painel Instrumentos Bloqueio 4',
      arquivo: 'foto_painel_instrumentos_veiculo_bloqueio4'
    },
    {
      id: 'fotoLateral',
      label: 'Foto Lateral',
      arquivo: 'foto_lateral'
    },
    {
      id: 'fotoFrontal',
      label: 'Foto Frontal',
      arquivo: 'foto_frontal'
    }
  ]

  let ImagensObrigatoriasEnvio = ['fotoFrontal', 'fotoOdometro', 'fotoLateral', 'fotoTrava', 'fotoSensoresCabine', 'fotoSensoresBau', 'fotoLigacaoTelemetriaME', 'fotoLigacaoCaboAlimentacao', 'fotoIgnicao', 'fotoLigacaoBloqueio1']
  let ImagensObrigatoriasOmnimob = ['fotoLocalAlojamentoOmnimob', 'fotoLigacaoCaboAlimentacao', 'fotoLigacaoBloqueio1', 'fotoLigacaoBloqueio2', 'fotoLigacaoBloqueio3', 'fotoLigacaoBloqueio4', 'fotoLigacaoCabos485', 'fotoTecladoNumeroSerieOmnimob', 'fotoPainelInstrumentosVeiculoBloqueio1e2', 'fotoPainelInstrumentosVeiculoBloqueio3', 'fotoPainelInstrumentosVeiculoBloqueio4']

  useEffect(() => {
    if (!na) {
      history.push('/agenda');
    }
    else if (usuarioGestor === true && (!na || na)) {
      history.push('/gestor');
    }
    else if (classificacaoPrestador !== 2 && (props?.location?.state?.statusEntrada !== 'Aceito' || props?.location?.state?.ItensAprovadosOrcamento !== true)) {
      history.push(`/ordemservico/${na}`);
    }
  }, [na]);

  useEffect(() => {
    consultaDetalheOS();
    sensoresConfiguraveis();
  }, []);

  useEffect(() => {
    if (classificacaoPrestador !== 2) {
      if (os) getVistoria();
    }
  }, [os]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(os?.rastreador || '');
      setAlertMessage({ title: 'Sucesso!', body: 'Número de série copiado com sucesso!' });
      setAlertType('success');
      setAlertOpen(true);
    } catch (error) {
      setAlertMessage({ title: 'Erro', body: 'Falha ao copiar o número de série' });
      setAlertType('error');
      setAlertOpen(true);
    }
  };


  const handleOpenDialogVistoria = () => setOpenDialogVistoria(true);

  const handleCloseDialogVistoria = () => setOpenDialogVistoria(false);

  const fecharModalConfirmacao = () => {
    setAbrirModalConfirmacao(false);
  };

  const modalEnvioConfirmacao = async () => {
    setEfeitoCarregando(true);
    try {
      handleFecharOS();
    } finally {
      setEfeitoCarregando(false);
      setAbrirModalConfirmacao(false);
    }
  };

  const corpoImagemChecklist = async (ordemservicoID, tipo) => {
    setEfeitoCarregandoBaixarImagem(true);
    setNomeFotoBaixando(tipo.label);

    const imagemCarregada = imagens.find(i => i.nome.split('.')[0] === tipo.id);

    const response = await ordemServicoServico.buscarCorpoImagemOS(ordemservicoID, imagemCarregada.nome);

    if (!response.error) {
      const corpoImagem = await response.result;
      const documentBody = corpoImagem.documentBody;

      const link = document.createElement('a');
      link.href = reverterBaseImg(documentBody);
      link.download = imagemCarregada.nome;
      link.click();

      setEfeitoCarregandoBaixarImagem(false);
    } else {
      setAlertMessage({ title: 'Atenção!', body: 'Não foi possível carregar a imagem, tente novamente mais tarde!' });
      setAlertType('fail');
      setAlertOpen(true);
      setEfeitoCarregandoBaixarImagem(false);
    }
  }

  const consultaDetalheOS = async () => {
    setLoadVistoria(true);

    if (na !== undefined && na !== '') {
      setMensagemAlertaAcessoURL(false);
    } else {
      setMensagemAlertaAcessoURL(true);
    }

    let osResult = await ordemServicoServico.detalhesOS(na, areaAtendimentoID);

    let osOrcamento = '';
    if (classificacaoPrestador !== 2) {
      osOrcamento = await ordemServicoServico.listaOrcamentoOS(na, areaAtendimentoID)
    }

    if (osResult) {
      let imagens = [];

      let imagensInstalacao = tiposImagens.map(imagem => imagem.id);
      imagensInstalacao = imagensInstalacao.concat(tiposImagensOmnimob.map(imagem => imagem.id));

      if (osResult[0].imagensCheckList?.length > 0) {
        for (let i = 0; i < osResult[0].imagensCheckList?.length; i++) {
          const imagem = osResult[0].imagensCheckList[i];

          if (imagem.fileName) {
            const nomeArquivo = imagem.fileName.split('.')[0];

            let imagemInputEncontrada = imagensInstalacao.find(imagem => imagem === nomeArquivo);

            if (imagemInputEncontrada) {
              const indexExistente = imagens.findIndex(img => img.nome.split('.')[0] === nomeArquivo);

              if (indexExistente !== -1) {
                imagens[indexExistente] = {
                  nome: imagem.fileName,
                  tipo: imagem.mimeType
                };
              } else {
                imagens.push({
                  nome: imagem.fileName,
                  tipo: imagem.mimeType
                });
              }
            }
          }
        }
      }

      setImagens(imagens);

      let _os = {
        osID: osResult[0].tz_Ordem_ServicoId || '',
        activityId: osResult[0].activityId || '',
        cliente: osResult[0].cliente || '',
        solicitante: osResult[0].tz_nome_solicitante || '',
        telefone_solicitante: osResult[0].tz_telefone_solicitante || '',
        endereco: osResult[0].tz_Endereco_Rua || '',
        numero: osResult[0].tz_Endereco_Numero || '',
        cep: osResult[0].tz_endereco_cepIdName || '',
        cidade: osResult[0].tz_endereco_cidadeIdName || '',
        estado: osResult[0].tz_endereco_EstadoIdName || '',
        referencia: osResult[0].tz_referencia || '',
        razaoStatus: osResult[0].razaoStatus || '',
        inicio: osResult[0].data_inicio,
        fim: osResult[0].data_fim,
        na: osResult[0].na || '',
        plataforma: osResult[0].tz_PlataformaIdName || '',
        fim_garantia: osResult[0].tz_Data_Fim_Garantia,
        observacao: osResult[0].tz_Observacoes || '',
        status: osResult[0].descStatus || '',
        obsCliente: osResult[0].detalheCliente || '',
        bairro: osResult[0].tz_endereco_bairro || '',
        modificacao: osResult[0].modifiedOn || '',
        servico: osResult[0].serviceIdName || '',
        statusNA: osResult[0].descStatusNA || '',
        tipo: osResult[0].tipo || '',
        corVeiculo: osResult[0].cor || '',
        placa: osResult[0].placa || '',
        rastreador: osResult[0].tz_Num_Serie_Rastreador_Instalado || '',
        antena: osResult[0].tz_Numero_Serie_Antena_Contrato || '',
        firmware: osResult[0].tz_versao_firmware || '',
        tecnologia: osResult[0].tecnologia || '',
        tecnico: osResult[0].tz_Nome_tecnico_prestou_servico || '',
        plataformaERP: osResult[0].tz_Codigo_ERP || '',
        statusNACOD: osResult[0].statusNA || 0,
        localRastreador: osResult[0].tz_local_rastreador || '',
        renavam: osResult[0].renavam || '',
        clienteEmail: osResult[0].clienteEmail || '',
        marca: osResult[0].marcaid ? {
          id: osResult[0].marcaid
        } : null,
        modelo: osResult[0].modeloid ? {
          id: osResult[0].modeloid
        } : null,
        imagens: imagens && imagens.length > 0 ? imagens : {},
        numeroOS: osResult[0].os || '',
        statusOS: osResult[0].descStatus || ''
      };

      if (classificacaoPrestador !== 2) {
        if (osOrcamento) {
          let _itensOS = [];

          osOrcamento.map((o) => {
            let item = {
              itemID: o.tz_item_ordem_servicoId,
              produtoId: o.tz_ItemId,
              numeroProduto: o.produtoNumero,
              produto: o.produtoNome,
              quantidade: o.quantidade,
              descStatus: o.descStatus_aprovacao,
              statusAprovacao: o.status_aprovacao,
              motivoOrcamento: mapCodigoToMotivo(o.tz_motivo_orcamento)
            };

            _itensOS.push(item);

          });

          setItensOS(_itensOS);
        }
      }

      let baseInstalada = {
        bau_lateral: osResult[0].tz_bau_lateral || false,
        teclado_multimidia: osResult[0].tz_teclado_multimidia || false,
        painel_read_switch: osResult[0].tz_painel_read_switch || false,
        painel_micro_switch: osResult[0].tz_painel_micro_switch || false,
        bau_intermediario: osResult[0].tz_bau_intermediario || false,
        trava_bau_intermediria: osResult[0].tz_trava_bau_intermediria || false,
        telemetria: osResult[0].tz_telemetria || false,
        boto_panico: osResult[0].tz_boto_panico || false,
        setas_pulsantes: osResult[0].tz_setas_pulsantes || false,
        ignicao: osResult[0].tz_ignicao || false,
        teclado: osResult[0].tz_teclado || false,
        sirene: osResult[0].tz_sirene || false,
        bloqueio_solenoide: osResult[0].tz_bloqueio_solenoide || false,
        engate_eletronico: osResult[0].tz_engate_eletronico || false,
        bloqueio_eletronico: osResult[0].tz_bloqueio_eletronico || false,
        trava_bau_lateral: osResult[0].tz_trava_bau_lateral || false,
        engate_aspiral: osResult[0].tz_engate_aspiral || false,
        temperatura: osResult[0].tz_temperatura || false,
        portas_cabine: osResult[0].tz_portas_cabine || false,
        bau_traseiro: osResult[0].tz_bau_traseiro || false,
        painel: osResult[0].tz_painel || false,
        trava_quinta_roda: osResult[0].tz_trava_quinta_roda || false,
        setas_continuas: osResult[0].tz_setas_continuas || false,
        bateria_backup: osResult[0].tz_bateria_backup || false,
        sensorConfiguravel1: '-1',
        sensorConfiguravel2: '-1',
        sensorConfiguravel3: '-1',
        sensorConfiguravel4: '-1',
      };
      changeValue({
        txtNomeTecnico: _os.tecnico,
        txtPlaca: _os.placa,
        txtCor: _os.corVeiculo,
        drpMarca: _os.marca,
        drpModelo: _os.modelo,
        versaoFirmware: _os.firmware,
        txtLocalRastrador: '',
        txtRenavam: _os.renavam,

      });

      setOS(_os);
      setStatusNa(_os.statusNA);
      setBaseInstalada(baseInstalada);
      setLoadVistoria(false);
    } else if (osResult === undefined) {
      setLoadVistoria(false);
      if (usuarioGestor === true) {
        history.push('/gestor');
      } else {
        history.push('/agenda');
      }
    } else {
      setLoadVistoria(false);
    }
    setLoadVistoria(false);
  }

  const trataBaseImg = (img) => img.replace(/^data:image\/[a-z]+;base64,/, '');

  const reverterBaseImg = (img) => `data:image/png;base64,${img}`;

  const validaImagem = (tipo) => {
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/jpe', 'image/png', 'image/bmp'];
    return allowedTypes.includes(tipo);
  };

  const verificarConectividade = async () => {
    if (!navigator.onLine) {
      setAlertMessage({ title: 'Atenção!', body: 'Sem conexão com a internet. Verifique sua conexão e tente novamente.' });
      setAlertType('fail');
      setAlertOpen(true);
      return false;
    }
    try {
      const response = await fetch('https://www.google.com', { mode: 'no-cors' });
      return true;
    } catch (error) {
      setAlertMessage({ title: 'Atenção!', body: 'Sem conexão com a internet. Verifique sua conexão e tente novamente.' });
      setAlertType('fail');
      setAlertOpen(true);
      return false;
    }
  };

  const handlefoto = async (file, atr) => {
    const conexaoValida = await verificarConectividade();
    if (!conexaoValida) {
      return;
    }

    const MAX_SIZE_IMG = 1024 * 1024 * 5;

    if (file?.size > MAX_SIZE_IMG) {
      setAlertMessage({ title: 'Atenção!', body: 'O tamanho da imagem não pode ser maior que 5 MB!' });
      setAlertType('fail');
      setAlertOpen(true);
      if (inputFileRefs.current[atr]) {
        inputFileRefs.current[atr].value = '';
      }
      return;
    }

    if (file && validaImagem(file.type)) {
      if (fotosInput.imagem) {
        const index = fotosInput.nome.findIndex(nome => nome.split('.')[0] === atr);
        if (index !== -1) {
          setfotosInput(prevState => ({
            ...prevState,
            imagem: prevState.imagem.filter((_, i) => i !== index),
            nome: prevState.nome.filter((_, i) => i !== index),
            nomeImagem: prevState.nomeImagem.filter((_, i) => i !== index)
          }));
        }
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setfotosInput(prevState => ({
          ...prevState,
          imagem: [...(prevState.imagem || []), trataBaseImg(reader.result)],
          nome: [...(prevState.nome || []), `${atr}.${file.name.split('.')[1]}`],
          nomeImagem: [...(prevState.nomeImagem || []), `${atr}.${file.name.split('.')[1]}`]
        }));
      };
    } else {
      setAlertMessage({ title: 'Atenção!', body: 'Formato de imagem inválido, anexe imagens do tipo .jpe, .jpg, .jpeg, .png ou .bmp!' });
      setAlertType('fail');
      setAlertOpen(true);
      if (inputFileRefs.current[atr]) {
        inputFileRefs.current[atr].value = '';
        setfotosInput(prevState => ({
          ...prevState,
          imagem: null,
          nome: null,
          nomeImagem: null
        }));
      }
      return;
    }
  }

  const salvarImagensOS = async () => {
    const conexaoValida = await verificarConectividade();
    if (!conexaoValida) {
      return;
    }

    let ImagensObrigatoriasInput = [];

    if (!fotosInput.imagem || fotosInput.imagem?.length === 0) {
      setAlertMessage({ title: 'Atenção!', body: 'Nenhuma imagem foi selecionada!' });
      setAlertType('fail');
      setAlertOpen(true);
      return;
    }

    if (os?.servico && os?.servico.toUpperCase().startsWith('INSTALAÇÃO')) {
      if (os?.servico.toUpperCase().startsWith('INSTALAÇÃO – OMNIMOB')) {
        ImagensObrigatoriasInput = ImagensObrigatoriasOmnimob;
      } else {
        ImagensObrigatoriasInput = ImagensObrigatoriasEnvio;
      }
    } else {
      ImagensObrigatoriasInput = [];
    }

    const imagensObrigatoriasAnexadas = ImagensObrigatoriasInput.every(obrigatoria =>
      fotosInput.nome.some(nome => nome.includes(obrigatoria))
    );

    const imagensObrigatoriasCarregadas = ImagensObrigatoriasInput.every(obrigatoria =>
      imagens && imagens.nome && imagens.nome.some(nome => nome.split('.')[0] === obrigatoria)
    );

    if (imagensObrigatoriasCarregadas && !imagensObrigatoriasAnexadas) {
      setAlertMessage({ title: 'Atenção!', body: 'Por favor, anexe todas as imagens obrigatórias!' });
      setAlertType('fail');
      setAlertOpen(true);
      return;
    }

    setEfeitoCarregandoSalvarImagens(true);
    let imagensInput = [];
    let OrdemServicoID = os.osID;

    for (let i = 0; i < fotosInput.imagem?.length; i++) {
      imagensInput.push({
        imagem: fotosInput.imagem[i],
        nome: fotosInput.nome[i],
        nomeImagem: fotosInput.nomeImagem[i],
      });

      const response = await ordemServicoServico.salvarImagensOS(imagensInput, OrdemServicoID);

      if (response.error) {
        setAlertMessage({ title: 'Erro!', body: response.message });
        setAlertType('error');
        setAlertOpen(true);
        setEfeitoCarregandoSalvarImagens(false);
        return false;
      } else {
        imagensInput = [];
      }
    }

    setAlertMessage({ title: 'Sucesso!', body: 'Imagens salvas com sucesso!' });
    setAlertType('success');
    setAlertOpen(true);

    setEfeitoCarregandoSalvarImagens(false);

    setTimeout(() => {
      window.location.reload();
    }, 5000);

    return true;
  };

  const handleFecharOS = async () => {
    setLoading(true);

    const ordemServico = {
      ContratoID: os.osID,
      AtividadeServicoID: os.activityId,
      NumSerie: os.rastreador,
      Agendamento: os.na,
      serieAntena: os.antena,
      statusNA: os.statusNA,
      Latitude: '0',
      Longitude: '0',
      AreaAtendimentoId: areaAtendimentoID,
      localizacaoAtual: '',
      tecnico: inputs.txtNomeTecnico,
      versaoFirmware: inputs.versaoFirmware,
      pecaZatix: false,
      PlataformaERP: os.plataformaERP,
      MarcaID: inputs.drpMarca ? inputs.drpMarca.id : null,
      placaVeiculo: inputs.txtPlaca,
      ModeloVeiculo: inputs.drpModelo ? inputs.drpModelo.id : null,
      CorVeiculo: inputs.txtCor,
      RenavamVeiculo: inputs.txtRenavam,
      baseInstalada: {
        Bateria_Backup: baseInstalada.bateria_backup,
        Bau_Intermediario: baseInstalada.bau_intermediario,
        Bau_Lateral: baseInstalada.bau_lateral,
        Bau_Traseiro: baseInstalada.bau_traseiro,
        Bloqueio_Eletronico: baseInstalada.bloqueio_eletronico,
        Bloqueio_Solenoide: baseInstalada.bloqueio_solenoide,
        Boto_Panico: baseInstalada.boto_panico,
        Engate_Aspiral: baseInstalada.engate_aspiral,
        Engate_Eletronico: baseInstalada.engate_eletronico,
        Ignicao: baseInstalada.ignicao,
        Painel: baseInstalada.painel,
        Painel_Micro_Switch: baseInstalada.painel_micro_switch,
        Painel_Read_Switch: baseInstalada.painel_read_switch,
        Portas_Cabine: baseInstalada.portas_cabine,
        Setas_Continuas: baseInstalada.setas_continuas,
        Setas_Pulsantes: baseInstalada.setas_pulsantes,
        Sirene: baseInstalada.sirene,
        Teclado: baseInstalada.teclado,
        Telemetria: baseInstalada.telemetria,
        Temperatura: baseInstalada.temperatura,
        Trava_Bau_Intermediria: baseInstalada.trava_bau_intermediria,
        Trava_Bau_Lateral: baseInstalada.trava_bau_lateral,
        Trava_Bau_Traseira: baseInstalada.bau_traseiro,
        Trava_Quinta_Roda: baseInstalada.trava_quinta_roda,
        Teclado_Multimidia: baseInstalada.teclado_multimidia,
        sensor_configuravel1: parseInt(baseInstalada.sensorConfiguravel1),
        sensor_configuravel2: parseInt(baseInstalada.sensorConfiguravel2),
        sensor_configuravel3: parseInt(baseInstalada.sensorConfiguravel3),
        sensor_configuravel4: parseInt(baseInstalada.sensorConfiguravel4),
        tipo_trava_bau: -1,
        local_rastreador: inputs.txtLocalRastrador
      },
    };

    let result = null;
    if (statusNa !== 'Fechado') {
      result = await ordemServicoServico.atualizar(JSON.stringify(ordemServico));
      if (result.status === 200) {
        setStatusNa('Fechado');
        setAlertMessage({ title: 'Sucesso!', body: 'Operação executada com sucesso!' });
        setAlertType('success');
        setAlertOpen(true);
        consultaDetalheOS('');
      } else {
        let data = await result.json();
        let errorMessage = data.erro;
        if (errorMessage.startsWith('Server was unable to process request') || (errorMessage.startsWith('The underlying connection was closed')) || (errorMessage.startsWith('Cannot connect SQL'))) {
          errorMessage = 'Não foi possível finalizar a NA, tente novamente.';
        }
        else if (errorMessage.startsWith('NA sem veículo')) {
          errorMessage = 'A NA não possui veículo associado.';
        }
        else if (errorMessage.startsWith('Não existe Base Instalada para o número de série')) {
          errorMessage = 'O número de série não está relacionada a nenhuma base instalada.';
        }
        else if (errorMessage.startsWith('FICHA DE ATIVACAO DESATUALIZADA')) {
          errorMessage = 'É necessário atualizar a ficha de ativação antes de encerrar a NA.';
        }
        else if (errorMessage.startsWith('Necessário informar o Motivo da Manutenção')) {
          errorMessage = 'É necessário informar o motivo da manutenção na ordem de serviço.';
        }

        setAlertMessage({ title: 'Atenção!', body: errorMessage });
        setAlertType('fail');
        setAlertOpen(true);
      }
    } else {
      result = await ordemServicoServico.atualizar(JSON.stringify(ordemServico));
      if (result.status === 200) {
        setAlertMessage({ title: 'Sucesso!', body: 'Operação executada com sucesso!' });
        setAlertType('success');
        setAlertOpen(true);
      } else {
        let data = await result.json();
        let errorMessage = data.erro;
        if (errorMessage.startsWith('Server was unable to process request') || (errorMessage.startsWith('The underlying connection was closed')) || (errorMessage.startsWith('Cannot connect SQL'))) {
          errorMessage = 'Não foi possível finalizar a NA, tente novamente.';
        }
        else if (errorMessage.startsWith('NA sem veículo')) {
          errorMessage = 'A NA não possui veículo associado.';
        }
        else if (errorMessage.startsWith('Não existe Base Instalada para o número de série')) {
          errorMessage = 'O número de série não está relacionada a nenhuma base instalada.';
        }
        else if (errorMessage.startsWith('FICHA DE ATIVACAO DESATUALIZADA')) {
          errorMessage = 'É necessário atualizar a ficha de ativação antes de encerrar a NA.';
        }
        else if (errorMessage.startsWith('Necessário informar o Motivo da Manutenção')) {
          errorMessage = 'É necessário informar o motivo da manutenção na ordem de serviço.';
        }
        setAlertMessage({ title: 'Erro!', body: errorMessage });
        setAlertType('error');
        setAlertOpen(true);
      }
    }

    setLoading(false);
  }

  const renderVistoriaStatusAlert = (status, title) => {
    const alertStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' };

    switch (status) {
      case 'Aceito':
        return (
          <Alert severity="success" icon={<CheckCircleOutline style={{ color: 'green' }} />} style={alertStyle}>
            <AlertTitle>{title}</AlertTitle>
            Aceito
          </Alert>
        );

      case 'Recusado':
        return (
          <Alert severity="error" icon={<HighlightOff style={{ color: 'red' }} />} style={alertStyle}>
            <AlertTitle>{title}</AlertTitle>
            Recusado
          </Alert>
        );

      case 'Aguardando aprovação':
        return (
          <Alert severity="warning" icon={<HourglassBottomIcon style={{ color: 'orange' }} />} style={alertStyle}>
            <AlertTitle>{title}</AlertTitle>
            Aguardando aprovação
          </Alert>
        );

      default:
        return (
          <Alert severity="info" icon={<HourglassEmpty style={{ color: '#1976d2' }} />} style={alertStyle}>
            <AlertTitle>{title}</AlertTitle>
            Não Enviado
          </Alert>
        );
    }
  };

  async function getVistoria() {
    const { osID, activityId } = os;

    const responseVistoria = await vistoriaService.consultaVistoria(
      activityId,
      osID
    );

    if (responseVistoria?.error) {
      setAlertMessage({ title: 'Erro!', body: 'Não foi possível obter o status da vistoria.' });
      setAlertType('error');
      setAlertOpen(true);
    } else {
      if (responseVistoria) setDadosVistoria(responseVistoria);
    }
  }

  const sensoresConfiguraveis = async () => {
    let sensoresResult = await SensorService.configuravel();

    if (sensoresResult)
      setSensores(sensoresResult);
  }

  const verificaCondicionalParaFechamentoDaNA = () => {
    let ImagensObrigatoriasInput = [];
    if (os?.servico && (os?.servico.toUpperCase().startsWith('INSTALAÇÃO')) && classificacaoPrestador !== 2) {
      if (os?.servico.toUpperCase().startsWith('INSTALAÇÃO – OMNIMOB')) {
        ImagensObrigatoriasInput = ImagensObrigatoriasOmnimob;
      } else {
        ImagensObrigatoriasInput = ImagensObrigatoriasEnvio
      }
    } else {
      ImagensObrigatoriasInput = null;
    }

    const imagensObrigatoriasAnexadas = ImagensObrigatoriasInput?.every(obrigatoria =>
      imagens.some(img => img.nome.split('.')[0] === obrigatoria)
    );

    if (ImagensObrigatoriasInput !== null) {
      if (classificacaoPrestador === 2 && statusNa !== 'Fechado') {
        return false;
      } else {
        if ((statusVistoria(dadosVistoria, 'saida') === 'Aceito' || statusVistoria(dadosVistoria, 'saida') === 'Aguardando aprovação') && imagensObrigatoriasAnexadas && os?.statusOS === 'Concluido') {
          return false;
        } else {
          return true;
        }
      }
    } else {
      if (classificacaoPrestador === 2 && statusNa !== 'Fechado') {
        return false;
      } else {
        if ((statusVistoria(dadosVistoria, 'saida') === 'Aceito' || statusVistoria(dadosVistoria, 'saida') === 'Aguardando aprovação') && os?.statusOS === 'Concluido') {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const { inputs, changeValue } = useForm(handleFecharOS);

  return !openDialogVistoria ? (
    <>
      <MenuComponent />
      <CustomAlert
        open={alertOpen}
        message={alertMessage}
        type={alertType}
        onClose={() => setAlertOpen(false)}
      />

      <div style={{ margin: 30 }}>
        <h3 className={classes.tituloStyle}>Atividade de Serviço</h3>
        <h4 className={classes.subtituloStyle}>
          <span
            onClick={() => window.location.href = '/agenda'}
            className={classes.linkStyle}
            style={{ cursor: 'pointer' }}
          >
            Home
          </span> {'>'} Atividade de Serviço
        </h4>

        <Grid className={classes.actions} container>
          <Grid item xs={3} sm={1} md={1}>
            <Button
              onClick={handleBackOrdemServico}
              style={{ color: '#209BDE' }}
              startIcon={<ArrowBackIos />}
            >
              Voltar
            </Button>
          </Grid>
        </Grid>

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={abrirConfirmacaoModal}
          style={{ marginTop: 35, marginBottom: 10 }}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              fecharModalConfirmacao();
            }
          }}
        >
          <DialogTitle>
            <Grid container alignItems="center">
              <Grid item xs={10} sm={11}>
                Confirmação
              </Grid>
              <Grid item xs={2} sm={1}>
                <IconButton onClick={fecharModalConfirmacao}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'start', marginBottom: '16px' }}>
              Você tem certeza que deseja finalizar a NA?
            </Typography>
            <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'start' }}>
              <strong>OBS:</strong> A solicitação uma vez enviada não poderá ser cancelada.
            </Typography>
          </DialogContent>

          <DialogActions style={{ justifyContent: 'space-around' }}>
            <Button
              variant="contained"
              fullWidth
              disabled={efeitoCarregando}
              onClick={fecharModalConfirmacao}
              style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
            >
              Cancelar
            </Button>
            <BlueButton
              variant="contained"
              color="primary"
              size="large"
              disabled={efeitoCarregando}
              onClick={modalEnvioConfirmacao}
              style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
            >
              {efeitoCarregando ? 'Enviando...' : 'Confirmar'} {efeitoCarregando && <CircularProgress size={20} />}
            </BlueButton>
          </DialogActions>
        </Dialog>

        <Paper className={classes.paper}>
          <span className={classes.title}>Atividade de Serviço</span>
          {loadVistoria ? (
            <Grid container alignItems="center" justifyContent="center">
              <CircularProgress color="#209BDE" style={{ color: '#209BDE' }} />
            </Grid>
          ) : (
            <Grid container style={{ marginTop: 5, marginBottom: 10 }} spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Paper
                  elevation={3}
                  style={{
                    padding: '10px',
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <b style={{ color: '#9b9b9b' }}>NA:</b>
                  <span style={{ marginLeft: '8px' }}>{os?.na}</span>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Paper
                  elevation={3}
                  style={{
                    padding: '10px',
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <b style={{ color: '#9b9b9b' }}>Tecnologia:</b>
                  <span style={{ marginLeft: '8px' }}>{os?.tecnologia}</span>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Paper
                  elevation={3}
                  style={{
                    padding: '10px',
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <b style={{ color: '#9b9b9b' }}>Status NA:</b>
                  <span style={{ marginLeft: '8px' }}>{statusNa}</span>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Paper
                  elevation={3}
                  style={{
                    padding: '10px',
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <b style={{ color: '#9b9b9b' }}>Status Série:</b>
                  <span style={{ marginLeft: '8px' }}>{os?.rastreador}</span>
                  <Tooltip title="Copiar número de série">
                    <IconButton size="small" onClick={handleCopy} style={{ marginLeft: '8px' }}>
                      <FileCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Paper>
              </Grid>
            </Grid>

          )}
        </Paper>

        {classificacaoPrestador !== 2 && (
          <Paper className={classes.paper}>
            <span className={classes.title}>Status da Vistoria</span>
            {loadVistoria ? (
              <Grid container alignItems="center" justifyContent="center">
                <CircularProgress color="#209BDE" style={{ color: '#209BDE' }} />
              </Grid>
            ) : (
              <>
                <Grid container style={{ marginTop: 5, marginBottom: 10 }} spacing={2}>
                  <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={6} style={{ padding: 16 }}>
                      {renderVistoriaStatusAlert(statusVistoria(dadosVistoria, 'entrada'), 'Entrada')}
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: 16 }}>
                      {renderVistoriaStatusAlert(statusVistoria(dadosVistoria, 'saida'), 'Saída')}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        )}

        {classificacaoPrestador !== 2 && (
          <Paper className={classes.paper}>
            {os?.servico && (
              os?.servico.toUpperCase().startsWith('INSTALAÇÃO – OMNIMOB') ? (
                <span className={classes.title}>Fotos de Instalação Omnimob</span>
              ) : os?.servico.toUpperCase().startsWith('INSTALAÇÃO') ? (
                <span className={classes.title}>Fotos de Instalação</span>
              ) : (
                <span className={classes.title}>Fotos</span>
              )
            )}
            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Grid container style={{ flexGrow: 1 }} spacing={3} >
                {os?.servico.toUpperCase().startsWith('INSTALAÇÃO – OMNIMOB') ? (
                  tiposImagensOmnimob.map(tipo => (
                    <Grid item xs={12} sm={6} md={4} key={tipo.id}>
                      <TextField
                        style={{ width: '75%', marginRight: '10px', lineHeight: '-1.5625em' }}
                        type="file"
                        id={tipo.id}
                        onChange={(event) => handlefoto(event.target.files[0], tipo.id)}
                        label={
                          os?.servico.toUpperCase().startsWith('INSTALAÇÃO – OMNIMOB') ? (
                            ImagensObrigatoriasOmnimob.includes(tipo.id) ? (
                              <>
                                <span title={tipo.label} style={{ whiteSpace: 'nowrap' }}>{tipo.label}</span>
                                <span style={{ color: 'red' }}> *</span>
                                <br />
                              </>
                            ) : (
                              <>
                                <span title={tipo.label} style={{ whiteSpace: 'nowrap' }}>{tipo.label}</span>
                                <br />
                              </>
                            )
                          ) : (
                            ImagensObrigatoriasEnvio.includes(tipo.id) ? (
                              <>
                                <span title={tipo.label} style={{ whiteSpace: 'nowrap' }}>{tipo.label}</span>
                                <span style={{ color: 'red' }}> *</span>
                                <br />
                              </>
                            ) : (
                              <>
                                <span title={tipo.label} style={{ whiteSpace: 'nowrap' }}>{tipo.label}</span>
                                <br />
                              </>
                            )
                          )
                        }
                        multiple={false}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        inputRef={el => inputFileRefs.current[tipo.id] = el}
                      />
                      {imagens?.find(i => i.nome.split('.')[0] === tipo.id) && (
                        <IconButton
                          aria-controls="fade-menu"
                          aria-haspopup="true"
                          style={{ backgroundColor: '#209BDE', color: '#FFF' }}
                          onClick={() => {
                            corpoImagemChecklist(os.osID, tipo);
                          }}
                          title={`Visualizar Anexo da ${tipo.label}`}
                          disabled={efeitoCarregandoBaixarImagem && nomeFotoBaixando === tipo.label}
                        >
                          {nomeFotoBaixando === tipo.label && efeitoCarregandoBaixarImagem && (
                            <CircularProgress size={20} style={{ color: '#FFF' }} />
                          ) || (
                              <DownloadIcon />
                            )}
                        </IconButton>
                      )}
                    </Grid>
                  ))
                ) : (
                  tiposImagens.map(tipo => (
                    <Grid item xs={12} sm={6} md={4} key={tipo.id}>
                      <TextField
                        style={{ width: '75%', marginRight: '10px' }}
                        type="file"
                        id={tipo.id}
                        disabled={statusNa === 'Fechado'}
                        onChange={(event) => handlefoto(event.target.files[0], tipo.id)}
                        label={
                          os?.servico.toUpperCase().startsWith('INSTALAÇÃO') ? (
                            ImagensObrigatoriasEnvio.includes(tipo.id) ? (
                              <>
                                {tipo.label}
                                <span style={{ color: 'red' }}> *</span>
                              </>
                            ) : (
                              tipo.label
                            )
                          ) : (
                            tipo.label
                          )
                        }
                        multiple={false}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        inputRef={el => inputFileRefs.current[tipo.id] = el}
                      />
                      {imagens?.find(i => i.nome.split('.')[0] === tipo.id) && (
                        <IconButton
                          aria-controls="fade-menu"
                          aria-haspopup="true"
                          style={{ backgroundColor: '#209BDE', color: '#FFF' }}
                          onClick={() => {
                            corpoImagemChecklist(os.osID, tipo);
                          }}
                          title={`Visualizar Anexo da ${tipo.label}`}
                          disabled={efeitoCarregandoBaixarImagem && nomeFotoBaixando === tipo.label}
                        >
                          {nomeFotoBaixando === tipo.label && efeitoCarregandoBaixarImagem && (
                            <CircularProgress size={20} style={{ color: '#FFF' }} />
                          ) || (
                              <DownloadIcon />
                            )}
                        </IconButton>
                      )}
                    </Grid>
                  ))
                )}
              </Grid>
            </div>

            <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
              <Grid item xs={12} sm={3} style={{ textAlign: 'right', marginTop: '20px' }}>
                <BlueButton
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  startIcon={efeitoCarregandoSalvarImagens ? <CircularProgress size={20} /> : <SaveIcon />}
                  onClick={salvarImagensOS}
                  style={{ height: 55 }}
                  disabled={efeitoCarregandoSalvarImagens || statusNa === 'Fechado'}
                >
                  <span style={{ fontSize: '0.7rem' }}>
                    {os?.servico && (
                      os?.servico.toUpperCase().startsWith('INSTALAÇÃO – OMNIMOB') ? (
                        efeitoCarregandoSalvarImagens ? ' Salvando... ' : ' Salvar Fotos de Instalação Omnimob'
                      ) : os?.servico.toUpperCase().startsWith('INSTALAÇÃO') ? (
                        efeitoCarregandoSalvarImagens ? ' Salvando... ' : ' Salvar Fotos de Instalação'
                      ) : (
                        efeitoCarregandoSalvarImagens ? ' Salvando... ' : ' Salvar Fotos'
                      )
                    )}
                  </span>
                </BlueButton>
              </Grid>
            </Grid>
          </Paper>
        )}

        {classificacaoPrestador !== 2 && statusNa !== 'Fechado' && (
          <div>
            <ExpansionPanel style={{ marginBottom: 20, borderRadius: 10 }}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Grid item xs={12} className={classes.expansionSubtitle}>
                  Ativação Integrada
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <FormularioAtivacaoComponent activityId={os?.activityId} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        )}

        {statusNa !== 'Fechado' && (
          <div>
            <ExpansionPanel style={{ marginBottom: 20, borderRadius: 10 }}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Grid item xs={12} className={classes.expansionSubtitle}>
                  Base Instalada
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {os && <BaseInstaladaComponent na={os?.na} />}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        )}

        <Grid container spacing={2} alignItems="center" justifyContent="flex-end" style={{ paddingTop: 10, paddingBottom: 5 }}>
          {classificacaoPrestador !== 2 && (
            <Grid item xs={12} sm={3} md={2}>
              <BlueButton
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                startIcon={<AssignmentIcon />}
                onClick={handleOpenDialogVistoria}
                style={{ minHeight: '56px', maxHeight: '56px' }}
                // disabled={statusVistoria(dadosVistoria, 'saida') === 'Aceito' ? true : false}
                disabled={mensagemAlertaAcessoURL}
              >
                <span style={{ fontSize: '0.7rem' }}>{statusVistoria(dadosVistoria, 'saida') === 'Aceito' ? 'Visualizar Vistoria' : 'Vistoria de Saída'}</span>
              </BlueButton>
            </Grid>
          )}

          <Grid item xs={12} sm={3} md={2}>
            <BlueButton
              variant="contained"
              color="secondary"
              fullWidth
              size="large"
              type="submit"
              onClick={statusNa !== 'Fechado' ? () => setAbrirModalConfirmacao(true) : handleFecharOS}
              disabled={statusNa === 'Fechado' || verificaCondicionalParaFechamentoDaNA() || loading}
              startIcon={loading ? <CircularProgress size={20} /> : <CloseIcon />}
              style={{ minHeight: '56px', maxHeight: '56px' }}
            >
              <span style={{ fontSize: '0.7rem' }}>FINALIZAR NA</span>
            </BlueButton>
          </Grid>

          <Grid item xs={12} sm={3} md={2}>
            <BlueButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                props.history.push('/agenda');
              }}
              style={{ minHeight: '56px', maxHeight: '56px' }}
              startIcon={<TodayIcon />}
            >
              <span style={{ fontSize: '0.7rem' }}>VOLTAR PARA AGENDA</span>
            </BlueButton>
          </Grid>
        </Grid>

        {
          classificacaoPrestador !== 2 && verificaCondicionalParaFechamentoDaNA() && (
            <Alert severity='error' style={{ marginTop: 10, marginBottom: 10 }}>
              OBS: O botão de finalizar NA ficará disponível após vistoria de saída ser enviada
              {os?.servico?.toUpperCase().startsWith('INSTALAÇÃO') ?
                ' e as fotos da instalação enviadas!' :
                '!'}
            </Alert>
          )
        }

        {
          mensagemAlertaAcessoURL && (
            <Alert severity='error' style={{ marginTop: 10, marginBottom: 10 }}>
              OBS: O número da NA não está disponível porque o acesso à tela de atividade de serviço foi feito diretamente via URL. Por favor, volte para a agenda e tente novamente.
            </Alert>
          )
        }
      </div>
    </>
  ) : (
    <>
      <MenuComponent />
      <OSVistoriaPage
        ordemServico={os}
        handleBack={handleCloseDialogVistoria}
        itensOS={itensOS}
        tipoVistoria="saida"
      />
    </>
  );
}