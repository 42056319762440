import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Dialog,
  DialogActions,
  DialogContent, InputAdornment,
  DialogTitle, CircularProgress,
  TextField
} from '@material-ui/core';
import BlueButton from '../components/button/BlueButton';
import perfilService from '../Services/Perfil/perfil-service';
import authService from '../Services/auth/auth-service';
import Button from '@material-ui/core/Button';
import CustomAlert from '../components/alert/CustomAlert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  validationText: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  validationIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function TrocarSenhaComponent({ isOpen, handleClose }) {
  const classes = useStyles();
  const [load, setLoad] = useState(false);
  const [senhaAntiga, setSenhaAntiga] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmarNovaSenha, setConfirmarNovaSenha] = useState('');
  const [open, setOpen] = useState(isOpen);
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [tipoAlert, setTipoAlert] = useState('error');
  const [mostrarSenhAntiga, setMostrarSenhaAntiga] = useState(false);
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [mostrarConfirmacaoSenha, setMostrarConfirmacaoSenha] = useState(false);

  const senhaTecnicoVisivel = () => {
    setMostrarSenha(!mostrarSenha);
  };

  const senhaConfirmacaoTecnicoVisivel = () => {
    setMostrarConfirmacaoSenha(!mostrarConfirmacaoSenha);
  };

  const senhaAntigaVisivel = () => {
    setMostrarSenhaAntiga(!mostrarSenhAntiga);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const salvar = async () => {
    if (senhaValidada.hasDigit == true && senhaValidada.hasLower == true && senhaValidada.hasSpecial == true && senhaValidada.hasUpper == true && senhaValidada.minLength == true) {
      setLoad(true);
      let _validaDados = validaDados();
      if (_validaDados) {
        let response = await perfilService.AlterarSenha(JSON.stringify({
          senha_antiga: senhaAntiga,
          nova_senha: novaSenha,
          login: authService.getUserInfo().usuario.login
        }));

        if (response.success === true) {
          setMensagemAlert({ title: 'Sucesso!', body: 'Sua senha foi alterada com sucesso!' });
          setTipoAlert('success');
          setAbrirAlert(true);

          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } else {
          setMensagemAlert({ title: 'Erro!', body: response.erro });
          setTipoAlert('error');
          setAbrirAlert(true);

        }
      }
      setLoad(false);
    } else {
      setMensagemAlert({ title: 'Atenção!', body: 'A senha precisa atender a todos os critérios. Por favor, verifique e tente novamente.' });
      setTipoAlert('fail');
      setAbrirAlert(true);
    }
  };

  const handleFechar = () => {
    setSenhaAntiga('');
    setNovaSenha('');
    setConfirmarNovaSenha('');
    handleClose();
  };

  const validaDados = () => {
    let campos = '';
    if (!senhaAntiga) campos = '[Senha Antiga]';
    if (!novaSenha) campos += ' [Nova Senha]';
    if (!confirmarNovaSenha) campos += ' [Confirmar Nova Senha]';
    if (campos.length > 0) {
      setMensagemAlert({ title: 'Atenção!', body: `Por favor, preencha os seguintes campos: ${campos}` });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return false;
    }
    if (novaSenha !== confirmarNovaSenha) {
      setMensagemAlert({ title: 'Atenção!', body: 'A confirmação da nova senha não coincide com a nova senha. Por favor, verifique e tente novamente.' });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return false;
    }
    return true;
  };

  const validarSenha = (senha) => {
    const minLength = senha.length >= 9;
    const hasLower = /[a-z]/.test(senha);
    const hasUpper = /[A-Z]/.test(senha);
    const hasDigit = /\d/.test(senha);
    const hasSpecial = /[^A-Za-z\d]/.test(senha);

    return {
      minLength,
      hasLower,
      hasUpper,
      hasDigit,
      hasSpecial,
    };
  };

  const senhaValidada = validarSenha(novaSenha);

  return (
    <>
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />

      <Dialog
        aria-labelledby="customized-dialog-title"
        onClose={handleFechar}
        open={open}
        style={{ marginTop: 35, marginBottom: 10 }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Grid container alignItems="center">
            <Grid item xs={10} sm={11}>
              Alterar Senha
            </Grid>
            <Grid item xs={2} sm={1}>
              <IconButton onClick={handleFechar}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.root}>
            <TextField
              fullWidth
              variant="outlined"
              name="senhaAntiga"
              type={mostrarSenhAntiga ? 'text' : 'password'}
              label={<span>Senha Antiga <span style={{ color: 'red' }}> *</span></span>}
              onChange={(event) => setSenhaAntiga(event.target.value)}
              value={senhaAntiga}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={senhaAntigaVisivel}>
                      {mostrarSenhAntiga ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: '16px', marginTop: '16px' }}
            />
            <TextField
              fullWidth
              variant="outlined"
              name="novaSenha"
              type={mostrarSenha ? 'text' : 'password'}
              label={<span>Nova Senha <span style={{ color: 'red' }}> *</span></span>}
              onChange={(event) => setNovaSenha(event.target.value)}
              value={novaSenha}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={senhaTecnicoVisivel}>
                      {mostrarSenha ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: '16px' }}
            />
            <TextField
              fullWidth
              variant="outlined"
              name="confirmarNovaSenha"
              type={mostrarConfirmacaoSenha ? 'text' : 'password'}
              label={<span>Confirmar Nova Senha <span style={{ color: 'red' }}> *</span></span>}
              onChange={(event) => setConfirmarNovaSenha(event.target.value)}
              value={confirmarNovaSenha}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={senhaConfirmacaoTecnicoVisivel}>
                      {mostrarConfirmacaoSenha ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: '16px' }}
            />
            <div className={classes.validationText}>
              A nova senha deve ter os seguintes critérios:
            </div>
            <div className={classes.validationText}>
              <div className={classes.validationIcon}>
                {senhaValidada.minLength ? <CheckIcon color="primary" /> : <CloseIcon color="error" />}
              </div>
              Mínimo de 9 caracteres
            </div>
            <div className={classes.validationText}>
              <div className={classes.validationIcon}>
                {senhaValidada.hasLower ? <CheckIcon color="primary" /> : <CloseIcon color="error" />}
              </div>
              Pelo menos uma letra minúscula
            </div>
            <div className={classes.validationText}>
              <div className={classes.validationIcon}>
                {senhaValidada.hasUpper ? <CheckIcon color="primary" /> : <CloseIcon color="error" />}
              </div>
              Pelo menos uma letra maiúscula
            </div>
            <div className={classes.validationText}>
              <div className={classes.validationIcon}>
                {senhaValidada.hasDigit ? <CheckIcon color="primary" /> : <CloseIcon color="error" />}
              </div>
              Pelo menos um número
            </div>
            <div className={classes.validationText}>
              <div className={classes.validationIcon}>
                {senhaValidada.hasSpecial ? <CheckIcon color="primary" /> : <CloseIcon color="error" />}
              </div>
              Pelo menos um caractere especial
            </div>
          </div>
        </DialogContent>

        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button
            variant="contained"
            fullWidth
            onClick={handleFechar}
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
          >
            Cancelar
          </Button>
          <BlueButton
            variant="contained"
            color="primary"
            size="large"
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
            onClick={salvar}
            disabled={load}
            startIcon={load ? <CircularProgress size={20} /> : ''}
          >
            {load ? 'Alterando...' : 'Alterar'}
          </BlueButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
