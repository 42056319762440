import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import {
    TextField,
    useMediaQuery, useTheme, InputAdornment,
    Paper, Dialog, CircularProgress, Box,
    DialogActions, DialogContent,
    DialogTitle, Button, DialogContentText, Typography
} from '@material-ui/core';
import MenuComponent from '../components/menu/menu-component';
import BlueButton from '../components/button/BlueButton';
import CustomTablePagination from '../components/pagination/pagination-component';
import authService from '../Services/auth/auth-service';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomAlert from '../components/alert/CustomAlert';
import perfilTecnicoService from '../Services/perfilTecnico/perfil-tecnico-services';
import debounce from 'lodash.debounce';
import FilesExportFilterEstoque from '../servicos/components/filesExportFilterServicos';


//Icons
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import BlockIcon from '@mui/icons-material/Block';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import FilesExportFilterTecnico from '../servicos/components/filesExportFilterTecnicos';

const useStyles = makeStyles((theme) => ({
    itemTexto: {
        fontFamily: 'Mont-Regular, sans-serif',
        fontSize: '16px',
    },
    paper: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        borderRadius: 10,
        width: '100%',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '10px',
        }
    },
    tituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
        marginBottom: '5px'
    },
    mensagemStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
    },
    subtituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#7F7F7F',
        fontSize: '16px',
        fontWeight: 'inherit',
        textAlign: 'start',
        padding: '0 0px',
        marginTop: '0px'
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
}));

const useStylesSenhaCriterios = makeStyles((theme) => ({
    passwordCriteria: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
}));

const PasswordCriteria = ({ label, valid }) => {
    const classesCriterioSenha = useStylesSenhaCriterios();
    return (
        <Typography variant="body2" className={classesCriterioSenha.passwordCriteria}>
            {valid ? <CheckIcon color="primary" /> : <CloseIcon color="error" />}
            {label}
        </Typography>
    );
};

export default function PerfilTecnicoPage({ microSigaID, handleCloseSolicitacaoCadastroestorTecnico, nomeTecnico }) {
    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(800));
    const [registroTecnicoID, setRegistroTecnicoID] = useState('');
    const [page, setPage] = useState(0);
    let [pageSize, setPageSize] = useState(8);
    const [totalDados, setTotalDados] = useState(0);
    const [dadosTecnico, setDadosTecnico] = useState([]);
    const [carregandoTable, setCarregandoTable] = useState(false);
    const [carregandoTela, setCarregandoTela] = useState(true);
    const [carregandoCadastro, setCarregandoCadastro] = useState(false);
    const [carregandoEdicao, setCarregandoEdicao] = useState(false);
    const [carregandoDelete, setCarregandoDelete] = useState(false);
    const [carregandoInativar, setCarregandoInativar] = useState(false);
    const [tableKey, setTableKey] = useState(0);
    const [pesquisa, setPesquisa] = useState('');
    const [tecnicoNome, setTecnicoNome] = useState('');
    const [tecnicoLogin, setTecnicoLogin] = useState('');
    const [tecnicoCPF, setTecnicoCPF] = useState('');
    const [tecnicoSenha, setTecnicoSenha] = useState('');
    const [tecnicoTelefone, setTecnicoTelefone] = useState('');
    const [senhaValida, setSenhaValida] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        special: false,
    });
    const [abrirModalCadastro, setAbrirModalCadastro] = useState(false);
    const [abrirModalEditar, setAbrirModalEditar] = useState(false);
    const [abrirModalDeletar, setAbrirModalDeletar] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ title: '', body: '' });
    const [alertType, setAlertType] = useState('success');
    const tecnicoPrestador = authService.getUserInfo().usuario.tecnico;
    const [abrirModalInativar, setAbrirModalInativar] = useState(false);
    const [confirmacaoTecnicoSenha, setConfirmacaoTecnicoSenha] = useState('');
    const [mostrarSenha, setMostrarSenha] = useState(false);
    const [mostrarConfirmacaoSenha, setMostrarConfirmacaoSenha] = useState(false);

    const [dadosExportacao, setDadosExportacao] = useState([]);

    const senhaTecnicoVisivel = () => {
        setMostrarSenha(!mostrarSenha);
    };

    const senhaConfirmacaoTecnicoVisivel = () => {
        setMostrarConfirmacaoSenha(!mostrarConfirmacaoSenha);
    };

    const abrirModalCadastroTecnico = async () => {
        setAbrirModalCadastro(true);
    }

    const fecharModalCadastroTecnico = async () => {
        setTecnicoLogin('');
        setTecnicoNome('');
        setTecnicoCPF('');
        setTecnicoSenha('');
        setAbrirModalCadastro(false);
    }
    useEffect(() => {
        prepararDadosExportacao();
      }, [pesquisa, totalDados]);

    const abrirModalEditarTecnico = async (tecnico) => {
        setTecnicoCPF(tecnico.cpf);
        setTecnicoNome(tecnico.nome);
        setTecnicoLogin(tecnico.login);
        setRegistroTecnicoID(tecnico.id);
        setTecnicoTelefone(tecnico.telefone);
        setAbrirModalEditar(true);
    }

    const fecharModalEditarTecnico = async () => {
        setAbrirModalEditar(false);
        setTecnicoLogin('');
        setTecnicoNome('');
        setTecnicoCPF('');
        setTecnicoTelefone('');
        setRegistroTecnicoID('');
    }

    const abrirModalDeletarTecnico = async (tecnico) => {
        setRegistroTecnicoID(tecnico.id)
        setTecnicoNome(tecnico.nome);
        setAbrirModalDeletar(true);
    }

    const abrirModalInativarTecnico = async (tecnico) => {
        setRegistroTecnicoID(tecnico.id)
        setTecnicoNome(tecnico.nome);
        setAbrirModalInativar(true);
    }

    const fecharModalInativarTecnico = async () => {
        setRegistroTecnicoID('')
        setTecnicoNome('');
        setAbrirModalInativar(false);
    }

    const fecharModalDeletarTecnico = async () => {
        setRegistroTecnicoID('')
        setTecnicoNome('');
        setAbrirModalDeletar(false);
    }

    const limparPesquisa = () => {
        setPesquisa('');
        buscarTecnicos(0, pageSize, '');
    };

    const cpfChange = (event) => {
        let cpf = event.target.value;

        cpf = cpf.replace(/\D/g, '');

        cpf = cpf.slice(0, 11);

        let cpfFormatado = '';
        for (let i = 0; i < cpf.length; i++) {
            if (i === 3 || i === 6) {
                cpfFormatado += '.';
            }
            else if (i === 9) {
                cpfFormatado += '-';
            }
            cpfFormatado += cpf[i];
        }

        setTecnicoCPF(cpfFormatado)
    }

    const handleAdicionarTecnico = async () => {
        if (tecnicoCPF && tecnicoSenha && tecnicoLogin && tecnicoNome && tecnicoTelefone) {
            setCarregandoCadastro(true);

            if (tecnicoSenha !== confirmacaoTecnicoSenha) {
                setAlertMessage({ title: 'Atenção!', body: 'As senhas devem ser iguais.' });
                setAlertType('fail');
                setAlertOpen(true);

                setCarregandoCadastro(false);
                return;
            }

            const dados = {
                nome: tecnicoNome,
                login: tecnicoLogin,
                cpf: tecnicoCPF.replace(/\D/g, ''),
                senha: tecnicoSenha,
                areaAtendimentoId: authService.getUserInfo().usuario.tz_area_atendimento_microsigaid,
                localId: authService.getUserInfo().usuario.localid,
                telefone: tecnicoTelefone.replace(/\D/g, ''),
            }

            try {
                const response = await perfilTecnicoService.adicionarTecnico(dados);

                if (!response.error) {
                    setAlertMessage({ title: 'Sucesso!', body: 'Técnico cadastrado com sucesso!' });
                    setAlertType('success');
                    setAlertOpen(true);
                    setTecnicoLogin('');
                    setTecnicoNome('');
                    setTecnicoCPF('');
                    setTecnicoSenha('');
                    setRegistroTecnicoID('');
                    fecharModalCadastroTecnico();
                    setPage(0);
                    buscarTecnicos(0, pageSize, pesquisa);
                } else {
                    if (response.message.includes('CPF já está cadastrado')) {
                        setAlertMessage({ title: 'Erro!', body: 'Este CPF já está cadastrado. Por favor, verifique os dados.' });
                    } else if (response.message.includes('Login já está cadastrado')) {
                        setAlertMessage({ title: 'Erro!', body: 'Este login já está em uso. Por favor, escolha outro login.' });
                    } else if (response.message.includes('CPF inválido')) {
                        setAlertMessage({ title: 'Erro!', body: 'O CPF informado é inválido. Por favor, tente novamente.' });
                    } else {
                        setAlertMessage({ title: 'Erro!', body: response.message });
                    }
                    setAlertType('error');
                    setAlertOpen(true);
                }
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    setAlertMessage({ title: 'Erro!', body: 'Dados inválidos. Verifique os campos e tente novamente.' });
                } else {
                    setAlertMessage({ title: 'Erro!', body: 'Erro ao cadastrar técnico: ' + (error.message || 'Erro desconhecido') });
                }
                setAlertType('error');
                setAlertOpen(true);
            } finally {
                setCarregandoCadastro(false);
            }
        } else {
            setAlertMessage({ title: 'Atenção!', body: 'É necessário informar um nome, login, CPF, telefone e senha para realizar o cadastro.' });
            setAlertType('fail');
            setAlertOpen(true);
        }
    }

    const editarTecnico = async () => {
        if (!validarEmail(tecnicoLogin)) {
            setAlertMessage({ title: 'Erro!', body: 'Por favor, insira um e-mail válido no campo de login.' });
            setAlertType('error');
            setAlertOpen(true);
            return;
        }

        if (tecnicoCPF && registroTecnicoID && tecnicoLogin && tecnicoNome && tecnicoTelefone) {
            setCarregandoEdicao(true);

            const dados = {
                nome: tecnicoNome,
                login: tecnicoLogin,
                cpf: tecnicoCPF.replace(/\D/g, ''),
                telefone: tecnicoTelefone.replace(/\D/g, '')
            }

            const response = await perfilTecnicoService.editarTecnico(registroTecnicoID, dados);

            if (response.error == false) {
                setAlertMessage({ title: 'Sucesso!', body: 'Técnico alterado com sucesso!' });
                setAlertType('success');
                setAlertOpen(true);
                buscarTecnicos();
                fecharModalEditarTecnico();
            } else {
                setAlertMessage({ title: 'Erro!', body: 'Não foi possível alterar o técnico, tente novamente.' });
                setAlertType('error');
                setAlertOpen(true);
            }
            setCarregandoEdicao(false);
        } else {
            setAlertMessage({ title: 'Atenção!', body: 'É necessário informar um CPF, nome, login e telefone para realizar a edição.' });
            setAlertType('fail');
            setAlertOpen(true);
        }
    };

    const deletarTecnico = async () => {
        setCarregandoDelete(true);

        const response = await perfilTecnicoService.desativarTecnico(registroTecnicoID);
        if (response.error == false) {
            setAlertMessage({ title: 'Sucesso!', body: 'Técnico removido com sucesso!' });
            setAlertType('success');
            setAlertOpen(true);
            buscarTecnicos();
            fecharModalDeletarTecnico();
        } else {
            setAlertMessage({ title: 'Error!', body: 'Não foi possível deletar o técnico, tente novamente.' });
            setAlertType('error');
            setAlertOpen(true);
        }

        setCarregandoDelete(false);
    }

    const inativarTecnico = async () => {
        setCarregandoInativar(true);

        const response = await perfilTecnicoService.inativarTecnico(registroTecnicoID);
        if (response.error == false) {
            setAlertMessage({ title: 'Sucesso!', body: 'Técnico inativado com sucesso!' });
            setAlertType('success');
            setAlertOpen(true);
            buscarTecnicos();
            fecharModalInativarTecnico();
        } else {
            setAlertMessage({ title: 'Error!', body: 'Não foi possível inativar o técnico, tente novamente.' });
            setAlertType('error');
            setAlertOpen(true);
        }

        setCarregandoInativar(false);
    }
    const prepararDadosExportacao = async () => {
        setCarregandoTable(true);
        try {
            const dados = {
                pagina: 0,
                limite: totalDados,
                nome: pesquisa || '',
            };
    
            let area = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;
    
            const response = await perfilTecnicoService.listarTecnicos(
                microSigaID ? microSigaID : area,
                dados
            );
    
            if (response.dados) {
                const formatados = response.dados.map((tecnico) => ({
                    Nome: tecnico.nome || 'Não informado',
                    Login: tecnico.login || 'Não informado',
                    CPF: tecnico.cpf || 'Não informado',
                    Telefone: tecnico.telefone || 'Não informado',
                    Cadastro: tecnico.dataCadastro
                        ? moment(tecnico.dataCadastro).format('DD/MM/YYYY HH:mm:ss')
                        : 'Não informado',
                    Atualização: tecnico.dataAtualizacao
                        ? moment(tecnico.dataAtualizacao).format('DD/MM/YYYY HH:mm:ss')
                        : 'Não atualizado',
                    Status:
                        tecnico.aprovado === null
                            ? 'Aguardando aprovação'
                            : tecnico.aprovado === true && tecnico.ativo === true
                            ? 'Aprovado'
                            : tecnico.aprovado === true && tecnico.ativo === false
                            ? 'Inativado'
                            : 'Reprovado',
                }));
    
                setDadosExportacao(formatados);
            }
        } catch (error) {
            setAlertMessage({ title: 'Erro!', body: 'Erro ao preparar dados para exportação.' });
            setAlertType('error');
            setAlertOpen(true);
        } finally {
            setCarregandoTable(false);
        }
    };
    
    
    
    
    const buscarTecnicos = async (page, pageSize, pesquisa) => {
        setCarregandoTable(true);
        try {
            const dados = {
                pagina: page ? page : 0,
                limite: pageSize ? pageSize : 8,
                nome: pesquisa ? pesquisa : '',
            }

            let area =
                authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;

            const response = await perfilTecnicoService.listarTecnicos(microSigaID ? microSigaID : area, dados);

            if (response.dados) {
                setTotalDados(response.total)
                setDadosTecnico(response.dados);
            }
        } catch (error) {
            console.error("Erro ao buscar técnicos: ", error);
            setAlertMessage({ title: 'Error!', body: 'Erro ao buscar técnicos cadastrados.' });
            setAlertType('error');
            setAlertOpen(true);
        }
        setCarregandoTable(false)
    }

    const delayPesquisa = useCallback(
        debounce((nome) => {
            setPage(0);
            buscarTecnicos(0, pageSize, nome);
        }, 700), []
    );

    const valorPesquisa = (nome) => {
        if (nome !== pesquisa) {
            setPesquisa(nome);
            delayPesquisa(nome);
        }
    };

    const mudarPagina = (event, newPage) => {
        setPage(newPage);
        buscarTecnicos(newPage, pageSize, pesquisa);
    };

    const mudarTamanhoPagina = (event) => {
        setPageSize(parseInt(event.target.value, 8));
        setPage(0);
        buscarTecnicos(0, parseInt(event.target.value, 8), pesquisa);
    };

    const formataCPF = (cpf) => {
        return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
    };

    function formatarTelefone(numero) {
        if (!numero || numero.trim() === '') {
            return "";
        }

        const telefoneLimpo = numero.replace(/\D/g, '');

        if (telefoneLimpo.length > 11) {
            return telefoneLimpo.replace(/^(\d+)(\d{2})(\d{5})(\d{4})$/, "+$1 ($2) $3-$4");
        } else if (telefoneLimpo.length === 11) {
            return telefoneLimpo.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
        } else if (telefoneLimpo.length === 10) {
            return telefoneLimpo.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
        } else {
            return telefoneLimpo.replace(/^(\d{0,4})(\d{4})$/, "$1-$2");
        }
    }

    const handleCloseCadastro = (event, reason) => {
        if (reason !== 'backdropClick') {
            fecharModalCadastroTecnico();
        }
    };

    const handleCloseEdit = (event, reason) => {
        if (reason !== 'backdropClick') {
            fecharModalEditarTecnico();
        }
    };

    const handleCloseDel = (event, reason) => {
        if (reason !== 'backdropClick') {
            fecharModalDeletarTecnico();
        }
    };

    const handleCloseInativar = (event, reason) => {
        if (reason !== 'backdropClick') {
            fecharModalInativarTecnico();
        }
    };

    const validarEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    useEffect(() => {
        setCarregandoTela(true);
        buscarTecnicos(page, pageSize, pesquisa);
        setCarregandoTela(false);
        setTableKey(tableKey + 1)
    }, [mobile]);

    useEffect(() => {
        const length = tecnicoSenha.length > 9;
        const uppercase = /[A-Z]/.test(tecnicoSenha);
        const lowercase = /[a-z]/.test(tecnicoSenha);
        const number = /[0-9]/.test(tecnicoSenha);
        const special = /[^A-Za-z0-9]/.test(tecnicoSenha);

        setSenhaValida({ length, uppercase, lowercase, number, special });
    }, [tecnicoSenha]);

    if (carregandoTela) {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <MenuComponent />
            <CustomAlert
                open={alertOpen}
                message={alertMessage}
                type={alertType}
                onClose={() => setAlertOpen(false)}
            />
            {authService.getUserInfo().usuario.tecnico !== true ? (
                <>
                    <div style={{ margin: 30 }}>
                        <h3 className={classes.tituloStyle}>Técnicos</h3>
                        <h4 className={classes.subtituloStyle}>
                            <span
                                onClick={() => {
                                    const userInfo = authService.getUserInfo();
                                    if (userInfo.usuario.gestor) {
                                        window.location.assign('/gestor');
                                    } else {
                                        window.location.assign('/agenda');
                                    }
                                }}
                                className={classes.linkStyle}
                                style={{ cursor: 'pointer' }}
                            >
                                Home
                            </span>
                            {' > '}
                            Técnicos
                            {authService.getUserInfo().usuario.gestor === true &&
                                nomeTecnico &&
                                nomeTecnico.trim() !== '' ? (
                                <>
                                    {' > '}
                                    {nomeTecnico}
                                </>
                            ) : null}
                        </h4>

                        {authService.getUserInfo().usuario.gestor === true &&
                            nomeTecnico &&
                            nomeTecnico.trim() !== '' ? (
                            <Grid item xs={3} sm={1} md={1} style={{ marginBottom: 20 }}>
                                <Button
                                    onClick={handleCloseSolicitacaoCadastroestorTecnico}
                                    style={{ color: '#209BDE' }}
                                    startIcon={<ArrowBackIosIcon />}
                                >
                                    Voltar
                                </Button>
                            </Grid>
                        ) : null}

                        {authService.getUserInfo().usuario.gestor === false || authService.getUserInfo().usuario.gestor === null && (tecnicoPrestador == null || tecnicoPrestador == false) ? (
                            <Grid container spacing={2} alignItems="center" justifyContent="flex-end" style={{ marginBottom: 20 }}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <BlueButton
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        onClick={abrirModalCadastroTecnico}
                                        startIcon={<Inventory2Icon />}
                                        style={{ height: 55 }}
                                    >
                                        Cadastrar Técnico
                                    </BlueButton>
                                </Grid>
                                <Grid item xs={12} sm={6} md={5}>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        variant="outlined"
                                        label="Pesquisar Técnico"
                                        value={pesquisa}
                                        onChange={(e) => valorPesquisa(e.target.value)}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {pesquisa ? (
                                                        <IconButton onClick={limparPesquisa}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    ) : (
                                                        <SearchIcon />
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                            </Grid>
                        ) : null}

                        <Dialog
                            aria-labelledby="customized-dialog-title"
                            onClose={handleCloseCadastro}
                            open={abrirModalCadastro}
                            style={{ marginTop: 35, marginBottom: 10 }}
                            fullWidth
                            maxWidth="sm"
                        >
                            <DialogTitle>
                                <Grid container alignItems="center">
                                    <Grid item xs={10} sm={11}>
                                        Adicionar Técnico
                                    </Grid>
                                    <Grid item xs={2} sm={1}>
                                        <IconButton onClick={fecharModalCadastroTecnico}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent dividers>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={7}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Nome do técnico"
                                            id="nome"
                                            label={<span>Nome do técnico <span style={{ color: 'red' }}>*</span></span>}
                                            value={tecnicoNome}
                                            onChange={(e) => setTecnicoNome(e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={5}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="CPF do técnico"
                                            id="cpf"
                                            label={<span>CPF do técnico <span style={{ color: 'red' }}>*</span></span>}
                                            value={tecnicoCPF}
                                            onChange={cpfChange}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Login do técnico"
                                            id="login"
                                            label={<span>Login do técnico <span style={{ color: 'red' }}>*</span></span>}
                                            value={tecnicoLogin}
                                            onChange={(e) => setTecnicoLogin(e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Telefone do técnico"
                                            id="telefone"
                                            label={<span>Telefone do técnico <span style={{ color: 'red' }}>*</span></span>}
                                            value={formatarTelefone(tecnicoTelefone)}
                                            onChange={(e) => setTecnicoTelefone(e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Senha do técnico"
                                            id="senha"
                                            label={<span>Senha do técnico <span style={{ color: 'red' }}>*</span></span>}
                                            type={mostrarSenha ? 'text' : 'password'}
                                            onChange={(e) => setTecnicoSenha(e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                            autoComplete="new-password"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={senhaTecnicoVisivel}>
                                                            {mostrarSenha ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Confirmação da senha do técnico"
                                            id="confirmacaoSenha"
                                            label={<span>Confirmação da senha do técnico <span style={{ color: 'red' }}>*</span></span>}
                                            type={mostrarConfirmacaoSenha ? 'text' : 'password'}
                                            onChange={(e) => setConfirmacaoTecnicoSenha(e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                            autoComplete="confirm-new-password"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={senhaConfirmacaoTecnicoVisivel}>
                                                            {mostrarConfirmacaoSenha ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <PasswordCriteria label="Mínimo de 10 caracteres" valid={senhaValida.length} />
                                        <PasswordCriteria label="Letra maiúscula" valid={senhaValida.uppercase} />
                                        <PasswordCriteria label="Letra minúscula" valid={senhaValida.lowercase} />
                                        <PasswordCriteria label="Número" valid={senhaValida.number} />
                                        <PasswordCriteria label="Caractere especial" valid={senhaValida.special} />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: 'space-around' }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disabled={carregandoCadastro}
                                    onClick={fecharModalCadastroTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                    startIcon={<CancelIcon />}
                                >
                                    <span style={{ fontSize: '0.7rem' }}>Cancelar</span>
                                </Button>
                                <BlueButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={carregandoCadastro}
                                    onClick={handleAdicionarTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                    startIcon={carregandoCadastro ? <CircularProgress size={20} /> : <SaveIcon />}
                                >
                                    <span style={{ fontSize: '0.7rem' }}>{carregandoCadastro ? ' Cadastrando...' : ' Cadastrar'}</span>
                                </BlueButton>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            aria-labelledby="customized-dialog-title"
                            onClose={handleCloseEdit}
                            open={abrirModalEditar}
                            style={{ marginTop: 35, marginBottom: 10 }}

                        >
                            <DialogTitle>
                                <Grid container alignItems="center">
                                    <Grid item xs={10} sm={11}>
                                        Editar Técnico
                                    </Grid>
                                    <Grid item xs={2} sm={1}>
                                        <IconButton onClick={fecharModalEditarTecnico}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>
                                        <DialogContentText>
                                            Nome do técnico:
                                        </DialogContentText>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Nome do técnico"
                                            id="nomeTecnico"
                                            label={<span>Nome do técnico <span style={{ color: 'red' }}>*</span></span>}
                                            value={tecnicoNome}
                                            onChange={(e) => setTecnicoNome(e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <DialogContentText>
                                            Login do técnico:
                                        </DialogContentText>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Login do técnico"
                                            id="tecnicoLogin"
                                            label={<span>Login do técnico <span style={{ color: 'red' }}>*</span></span>}
                                            value={tecnicoLogin}
                                            onChange={(e) => setTecnicoLogin(e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <DialogContentText>
                                            CPF do técnico:
                                        </DialogContentText>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="CPF do técnico"
                                            id="CPFTecnico"
                                            label={<span>CPF do técnico <span style={{ color: 'red' }}>*</span></span>}
                                            value={formataCPF(tecnicoCPF)}
                                            onChange={cpfChange}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <DialogContentText>
                                            Telefone do técnico:
                                        </DialogContentText>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Telefone do técnico"
                                            id="TelefoneTecnico"
                                            label={<span>Telefone do técnico <span style={{ color: 'red' }}>*</span></span>}
                                            value={formatarTelefone(tecnicoTelefone)}
                                            onChange={(e) => setTecnicoTelefone(e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>

                            <DialogActions style={{ justifyContent: 'space-around' }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disabled={carregandoEdicao}
                                    onClick={fecharModalEditarTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                    startIcon={<CancelIcon />}
                                >
                                    <span style={{ fontSize: '0.7rem' }}>Cancelar</span>
                                </Button>
                                <BlueButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={carregandoEdicao}
                                    onClick={editarTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                    startIcon={carregandoEdicao ? <CircularProgress size={20} /> : <EditIcon />}
                                >
                                    <span style={{ fontSize: '0.7rem' }}>{carregandoEdicao ? ' Editando...' : ' Editar'}</span>
                                </BlueButton>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            aria-labelledby="customized-dialog-title"
                            onClose={handleCloseDel}
                            open={abrirModalDeletar}
                            style={{ marginTop: 35, marginBottom: 10 }}
                        >
                            <DialogTitle>
                                <Grid container alignItems="center">
                                    <Grid item xs={10} sm={11}>
                                        Confirmação
                                    </Grid>
                                    <Grid item xs={2} sm={1}>
                                        <IconButton onClick={fecharModalDeletarTecnico}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent dividers>
                                <DialogContentText>
                                    Você tem certeza que deseja remover a solicitação do técnico <strong>{tecnicoNome}</strong>?
                                </DialogContentText>
                            </DialogContent>

                            <DialogActions style={{ justifyContent: 'space-around' }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disabled={carregandoDelete}
                                    onClick={fecharModalDeletarTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                    startIcon={<CancelIcon />}
                                >
                                    <span style={{ fontSize: '0.7rem' }}>Cancelar</span>
                                </Button>
                                <BlueButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={carregandoDelete}
                                    onClick={deletarTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                    startIcon={carregandoDelete ? <CircularProgress size={20} /> : <DeleteIcon />}
                                >
                                    <span style={{ fontSize: '0.7rem' }}>{carregandoDelete ? ' Removendo...' : ' Remover'}</span>
                                </BlueButton>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            aria-labelledby="customized-dialog-title"
                            onClose={handleCloseInativar}
                            open={abrirModalInativar}
                            style={{ marginTop: 35, marginBottom: 10 }}
                        >
                            <DialogTitle>
                                <Grid container alignItems="center">
                                    <Grid item xs={10} sm={11}>
                                        Confirmação
                                    </Grid>
                                    <Grid item xs={2} sm={1}>
                                        <IconButton onClick={fecharModalInativarTecnico}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent dividers>
                                <DialogContentText>
                                    Você tem certeza que deseja inativar o acesso do técnico <strong>{tecnicoNome}</strong>?
                                </DialogContentText>
                            </DialogContent>

                            <DialogActions style={{ justifyContent: 'space-around' }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disabled={carregandoInativar}
                                    onClick={fecharModalInativarTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                    startIcon={<CancelIcon />}
                                >
                                    <span style={{ fontSize: '0.7rem' }}>Cancelar</span>
                                </Button>
                                <BlueButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={carregandoInativar}
                                    onClick={inativarTecnico}
                                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                                    startIcon={carregandoInativar ? <CircularProgress size={20} /> : <DesktopAccessDisabledIcon />}
                                >
                                    <span style={{ fontSize: '0.7rem' }}>{carregandoDelete ? ' Inativando...' : ' Inativar'}</span>
                                </BlueButton>
                            </DialogActions>
                        </Dialog>

                        <Paper className={classes.paper}>                
                            

                        {dadosTecnico.length > 0 && (
                            <Grid container spacing={2} alignItems="center" justifyContent="space-between" style={{ marginBottom: 20 }}>
                                <Grid item xs={mobile ? 12 : 10}>
                                </Grid>
                                <Grid item xs={mobile ? 12 : 2} style={{ textAlign: 'right', marginTop: mobile ? 10 : 0 }}>
                                        <FilesExportFilterTecnico
                                            fileName={`Técnicos cadastrados do ${nomeTecnico}`}
                                            data={dadosExportacao}
                                            className={classes.exportButton}
                                        />
                                </Grid>
                            </Grid>
                           )}
                            {carregandoTable ? (
                                <Grid container justifyContent="center" alignItems="center" style={{ height: '200px' }}>
                                    <CircularProgress />
                                </Grid>
                            ) : dadosTecnico.length === 0 ? (
                                <Grid container justifyContent="center" alignItems="center" style={{ height: '100px' }}>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        Não há técnicos para serem listados.
                                    </Typography>
                                </Grid>
                            ) : (
                                <>
                                    <Grid container spacing={1} alignItems="stretch">
                                        {dadosTecnico.map((tecnico) => (
                                            <Grid item key={tecnico.id} xs={12} md={3} style={{ width: '100%' }}>
                                                <Paper
                                                    style={{
                                                        padding: '16px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                        minHeight: '350px',
                                                        height: '100%',
                                                        boxSizing: 'border-box',
                                                    }}
                                                >
                                                    <Box padding={1} style={{ flexGrow: 1 }}>
                                                        <Typography component="div" variant="body2">
                                                            <strong>Nome: </strong>{tecnico.nome}
                                                        </Typography>
                                                        <Typography component="div" variant="body2">
                                                            <strong>Login:</strong> {tecnico.login}
                                                        </Typography>
                                                        <Typography component="div" variant="body2">
                                                            <strong>CPF:</strong> {formataCPF(tecnico.cpf)}
                                                        </Typography>
                                                        <Typography component="div" variant="body2">
                                                            <strong>Telefone:</strong> {tecnico.telefone ? formatarTelefone(tecnico.telefone) : 'Telefone não informado'}
                                                        </Typography>
                                                        <Typography component="div" variant="body2">
                                                            <strong>Cadastro:</strong> {moment(tecnico.dataCadastro).format('DD/MM/YYYY HH:mm:ss')}
                                                        </Typography>
                                                        <Typography component="div" variant="body2">
                                                            <strong>Atualização:</strong> {tecnico.dataAtualizacao ? moment(tecnico.dataAtualizacao).format('DD/MM/YYYY HH:mm:ss') : ''}
                                                        </Typography>
                                                    </Box>

                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={12}>
                                                            <div style={{
                                                                textAlign: 'center',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                color: 'white',
                                                                width: '100%',
                                                                height: 44,
                                                                marginTop: 10,
                                                                borderRadius: 15,
                                                                backgroundColor: tecnico.aprovado === null ? 'rgba(255, 206, 86, 1)' :
                                                                    (tecnico.aprovado === true && tecnico.ativo === true) ? 'rgba(3, 201, 169, 1)' :
                                                                        (tecnico.aprovado === true && tecnico.ativo === false) ? '#555' :
                                                                            'rgba(255, 99, 132, 1)',
                                                            }}>
                                                                {tecnico.aprovado === null && <HourglassEmptyIcon />}
                                                                {tecnico.aprovado === true && tecnico.ativo === false && <BlockIcon />}
                                                                {tecnico.aprovado === true && tecnico.ativo === true && <CheckCircleIcon />}
                                                                {tecnico.aprovado === false && tecnico.ativo === true && <CancelIcon />}

                                                                {tecnico.aprovado === null ? ' Aguardando aprovação' :
                                                                    (tecnico.aprovado === true && tecnico.ativo === true) ? ' Aprovado' :
                                                                        (tecnico.aprovado === true && tecnico.ativo === false) ? ' Inativado' :
                                                                            ' Reprovado'}
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            <BlueButton
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => abrirModalEditarTecnico(tecnico)}
                                                                startIcon={<EditIcon />}
                                                                disabled={tecnico.inserido}
                                                                style={{ height: 55 }}
                                                                fullWidth
                                                            >
                                                                Editar
                                                            </BlueButton>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            {(tecnico.aprovado === null && tecnico.inserido == false) || (tecnico.aprovado === false && tecnico.inserido == false) ? (
                                                                <BlueButton
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    onClick={() => abrirModalDeletarTecnico(tecnico)}
                                                                    startIcon={<DeleteIcon />}
                                                                    disabled={tecnico.inserido}
                                                                    style={{ height: 55 }}
                                                                    fullWidth
                                                                >
                                                                    Excluir
                                                                </BlueButton>
                                                            ) : (
                                                                <BlueButton
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    onClick={() => abrirModalInativarTecnico(tecnico)}
                                                                    startIcon={<DesktopAccessDisabledIcon />}
                                                                    disabled={!tecnico.ativo}
                                                                    style={{ height: 55 }}
                                                                    fullWidth
                                                                >
                                                                    Inativar
                                                                </BlueButton>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                                        <Grid item>
                                            <CustomTablePagination
                                                onPageChange={mudarPagina}
                                                page={page}
                                                count={totalDados}
                                                quantidadeItens={totalDados}
                                                rowsPerPage={pageSize}
                                                onRowsPerPageChange={mudarTamanhoPagina}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Paper>
                    </div>
                </>
            ) : (
                <div style={{ margin: 30 }}>
                    <h3 className={classes.tituloStyle}>Técnicos</h3>
                    <h4 className={classes.subtituloStyle}>
                        <span
                            onClick={() => {
                                const userInfo = authService.getUserInfo();
                                if (userInfo.usuario.gestor) {
                                    window.location.assign('/gestor');
                                } else {
                                    window.location.assign('/agenda');
                                }
                            }}
                            className={classes.linkStyle}
                            style={{ cursor: 'pointer' }}
                        >
                            Home
                        </span> {'>'} Técnicos
                    </h4>

                    <Paper className={classes.paper}>
                        <Grid container justifyContent="center">
                            <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>Você não tem permissão para acessar esta página!</h2>
                        </Grid>
                    </Paper>
                </div>
            )}
        </>
    );
}