import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class RemanufaturaService {
  
  async listarProdutosRemanufatura(dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/EstoqueRemanufatura/listarProdutosRemanufaturados?Pagina=${dados.pagina}&Limite=${dados.limite}&Pesquisa=${dados.pesquisa || ''}`, {
        method: 'GET',
        headers: _headerToken,
      });
      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados de remanufatura.',
      };
    }
  }

  async listarProdutosRemanufaturadosTecnicos() {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/EstoqueRemanufatura/listarProdutosRemanufaturadosTecnicos`, {
        method: 'GET',
        headers: _headerToken,
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados de remanufatura.',
      };
    }
  }
}

export default new RemanufaturaService();
