import React, { useEffect, useState } from 'react';
import { makeStyles, Modal, Backdrop, Fade, LinearProgress } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    borderRadius: '8px',
    padding: theme.spacing(2),
    textAlign: 'center',
    maxWidth: '300px',
    width: '100%',
    height: 'auto',
    position: 'relative',
  },
  success: {
    backgroundColor: '#e0f7e9',
    color: '#2e7d32',
    borderColor: '#2e7d32',
  },
  error: {
    backgroundColor: '#ffebee',
    color: '#c62828',
    borderColor: '#c62828',
  },
  fail: {
    title: 'Atenção!',
    backgroundColor: '#fff8e1',
    color: '#f57c00',
    borderColor: '#f57c00',
  },
  icon: {
    fontSize: '3rem',
    marginBottom: '0.5rem',
  },
  message: {
    marginBottom: '1rem',
  },
  progress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '5px',
  },
  barSuccess: {
    backgroundColor: '#006400',
  },
  barError: {
    backgroundColor: '#ff0000',
  },
  barFail: {
    backgroundColor: '#ff8c00',
  },
  colorPrimarySuccess: {
    backgroundColor: '#e0f7e9',
  },
  colorPrimaryError: {
    backgroundColor: '#ffebee',
  },
  colorPrimaryFail: {
    backgroundColor: '#fff8e1',
  },
}));

export default function CustomAlert({ open, message, type, onClose }) {
  const classes = useStyles();
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    if (open) {
      setProgress(100);
      const timer = setTimeout(onClose, 4000);
      const interval = setInterval(() => {
        setProgress((prev) => (prev > 0 ? prev - 0.25 : 0));
      }, 10);
      return () => {
        clearTimeout(timer);
        clearInterval(interval);
      };
    }
  }, [open, onClose]);

  const getIcon = () => {
    switch (type) {
      case 'success':
        return <CheckCircleOutlineIcon className={`${classes.icon}`} />;
      case 'error':
        return <ErrorOutlineIcon className={`${classes.icon}`} />;
      case 'fail':
        return <ReportProblemOutlinedIcon className={`${classes.icon}`} />;
      default:
        return null;
    }
  };

  const progressBarClass = {
    success: classes.barSuccess,
    error: classes.barError,
    fail: classes.barFail,
  }[type];

  const colorPrimaryClass = {
    success: classes.colorPrimarySuccess,
    error: classes.colorPrimaryError,
    fail: classes.colorPrimaryFail,
  }[type];

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={`${classes.paper} ${classes[type]}`}>
          {getIcon()}
          <h2 id="transition-modal-title" style={{ fontSize: '1.25rem', margin: '0.5rem 0' }}>{message.title}</h2>
          <p id="transition-modal-description" className={classes.message} style={{ fontSize: '0.875rem', margin: '0.5rem 0' }}>{message.body}</p>
          <LinearProgress
            className={`${classes.progress}`}
            classes={{
              colorPrimary: colorPrimaryClass,
              barColorPrimary: progressBarClass,
            }}
            variant="determinate"
            value={progress}
          />
        </div>
      </Fade>
    </Modal>
  );
}
