import React, { useState, useEffect,useRef  } from 'react';
import {
  Paper, Typography, Grid, Button, Box, IconButton,
  TextField, FormControl, MenuItem,
  InputLabel, Checkbox, Modal, Dialog, useMediaQuery,
  DialogActions, DialogContent, DialogTitle, CircularProgress,Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles'
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import MenuComponent from '../../components/menu/menu-component';
import CustomTablePagination from '../../components/pagination/pagination-component';
import authService from '../../Services/auth/auth-service';
import BlueButton from '../../components/button/BlueButton';
import CustomAlert from '../../components/alert/CustomAlert';
import notificacaoService from '../../Services/notificacoes/notificacoes-service';
import auditoriaService from '../../Services/Auditoria/auditoria-service';
import Select from 'react-select';

//Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import ClearIcon from '@material-ui/icons/Clear';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import EngineeringIcon from '@mui/icons-material/Engineering';
import MessageIcon from '@mui/icons-material/Message';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px',
  },
  menuPaper: {
    position: 'fixed',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    padding: '16px',
  },
  menuFooter: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: '#fff',
    padding: '8px',
    boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
    zIndex: 1,
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  
  modal: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: theme.shadows[5],
    borderRadius: 8,
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modalButton: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));

const NotificacoesGestor = () => {
  const classes = useStyles();
  const mobile = useMediaQuery(useTheme().breakpoints.down(800))
  const [openVisualizar, setOpenVisualizar] = useState(false);
  const [tecnicosVisualizar, setTecnicosVisualizar] = useState([]);
  const [dadosNotificacoes, setDadosNotificacoes] = useState([]);
  const [carregandoTable, setCarregandoTable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totalDados, setTotalDados] = useState(0);
  
  const [expandedId, setExpandedId] = useState(null);
  const [open, setOpen] = useState(false);
  const [tituloNotificacao, setTituloNotificacao] = useState('');
  const [textoNotificacao, setTextoNotificacao] = useState('');
  const [tecnicosSelecionados, setTecnicosSelecionados] = useState([]);
  const [editandoNotificacaoId, setEditandoNotificacaoId] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState({ title: '', body: '' });
  const [tipoAlert, setTipoAlert] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [dialogConfirm, setDialogConfirm] = useState(false);
  const [tecnicos, setTecnicos] = useState([]);
  const [permissaoGestor, setPermissaoGestor] = useState(0);
  const [submeterNotificacao, setSubmeterNotificacao] = useState(false);
  const [loading, setLoading] = useState(true);
  const [carregandoEnvioNotificacoes, setCarregandoEnvioNotificacoes] = useState(false);
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [carregandoBotaoLimpar, setCarregandoBotaoLimpar] = useState(false);
  const [carregandoBotaoPesquisar, setCarregandoBotaoPesquisar] = useState(false);
  const [modalConteudo, setModalConteudo] = useState(false);
  const [loteSelecionado, setLoteSelecionado] = useState('');
  const [totalDadosTecnicosLote, setTotalDadosTecnicosLote] = useState(0);
  const [paginaLoteTecnicos, setPaginaLoteTecnicos] = useState(0);
  const [tamanhoPaginaLoteTecnicos, setTamanhoPaginaLoteTecnicos] = useState(8);
  const [carregandoTecnicosLoteNotificacoes, setCarregandoTecnicosLoteNotificacoes] = useState(false);
  const [carregandoDadosTecnicosLoteNotificacoes, setCarregandoDadosTecnicosLoteNotificacoes] = useState(false);
  const [dialogExcluir, setDialogExcluir] = useState(false);
  const [notificacaoExcluir, setNotificacaoExcluir] = useState(null);
  const [originalTitulo, setOriginalTitulo] = useState('');
  const [originalTexto, setOriginalTexto] = useState('');


  const customStyles = {
    control: (base) => ({
      ...base,
      marginTop: '16px',
    }),
    menu: (base) => ({
      ...base,
      zIndex: 1050, // Certifique-se de que o menu não seja sobreposto
    }),
  };

  // Criando as opções para o react-select
  const options = tecnicos.map((tecnico) => ({
    value: tecnico.id,
    label: tecnico.nome,
  }));

  const allOption = { value: 'all', label: 'Selecionar Todos' };

  const handleChange = (selectedOptions) => {
    // Se "Selecionar Todos" for clicado
    if (selectedOptions.some((option) => option.value === 'all')) {
      const allSelected = tecnicosSelecionados.length === tecnicos.length;
      setTecnicosSelecionados(allSelected ? [] : tecnicos.map((tecnico) => tecnico.id));
    } else {
      // Atualiza a seleção individual dos técnicos
      setTecnicosSelecionados(selectedOptions.map((option) => option.value));
    }
  };

  
  const handleMenuOpen = () => setMenuOpen(true);
  const handleMenuClose = () => setMenuOpen(false);

  const expandirOpcoes = (id) => {
    setExpandedId(expandedId === id ? null : id); 
  };
  
  const abrirConteudoLote = (lote) => {
    setLoteSelecionado(lote);
    setModalConteudo(true);
  }

  const fecharConteudoLote = () => {
    setLoteSelecionado('');
    setModalConteudo(false);
  }

  const handleLimpar = async () => {
    setCarregandoBotaoLimpar(true);
    setDataInicial('');
    setDataFinal('');
    await buscarNotificacoes(page, pageSize, '', '');
    setCarregandoBotaoLimpar(false);
  };

  const aplicarFiltroDeDatas = async () => {
    if (!dataInicial || !dataFinal) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Por favor, selecione as datas de início e fim antes de pesquisar.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }

    if (moment(dataInicial).isAfter(dataFinal)) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Data inicial não pode ser maior que a data final.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }

    if (moment(dataInicial).isAfter(moment())) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Data inicial não pode ser maior que a data atual.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }

    setCarregandoBotaoPesquisar(true);
    setPage(0);
    await buscarNotificacoes(0, pageSize, dataInicial, dataFinal);
    setCarregandoBotaoPesquisar(false);
  };

  const mudarPagina = (event, newPage) => {
    setPage(newPage);
    buscarNotificacoes(newPage, pageSize, dataInicial, dataFinal);
  };

  const mudarTamanhoPagina = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
    buscarNotificacoes(0, parseInt(event.target.value, 10), dataInicial, dataFinal);
  };

  const abrirModalListagemTecnicosLote = async (idLote) => {
    setCarregandoTecnicosLoteNotificacoes(true);
    setLoteSelecionado(idLote);
    await listagemTecnicosLote(idLote, paginaLoteTecnicos, tamanhoPaginaLoteTecnicos)
    setOpenVisualizar(true);
    setCarregandoTecnicosLoteNotificacoes(false);
  };

  const mudarPaginaLoteTecnicos = (event, newPage) => {
    setPaginaLoteTecnicos(newPage);
    listagemTecnicosLote(loteSelecionado, newPage, tamanhoPaginaLoteTecnicos);
  };

  const mudarTamanhoLoteTecnicos = (event) => {
    setTamanhoPaginaLoteTecnicos(parseInt(event.target.value, 10));
    setPaginaLoteTecnicos(0);
    listagemTecnicosLote(loteSelecionado, paginaLoteTecnicos, parseInt(event.target.value, 10));
  };

  const listagemTecnicosLote = async (idLote, pagina, quantidade) => {
    setCarregandoDadosTecnicosLoteNotificacoes(true);
    setLoteSelecionado(idLote);
    const dados = {
      pagina: paginaLoteTecnicos || 0,
      limite: tamanhoPaginaLoteTecnicos || 8,
      lote: idLote || ''
    };

    const response = await notificacaoService.listarTecnicosLote(dados);

    if (!response.error) {
      setTotalDadosTecnicosLote(response.result.total);
      setTecnicosVisualizar(response.result.dados);
    } else {
      setTecnicosVisualizar([]);
      setTotalDadosTecnicosLote(0);
    }

    setOpenVisualizar(true);
    setCarregandoDadosTecnicosLoteNotificacoes(false);
  };

  useEffect(() => {
    async function fetchTecnicos() {
      const response = await auditoriaService.listarTecnicosAuditoria();
      if (response && !response.error) {
        setTecnicos(response);
      }
    }

    fetchTecnicos();
  }, []);

  const handleOpen = () => {
    setOpen(true);
    setEditandoNotificacaoId(null);
  };

  const handleClose = () => {
    setOpen(false);
    setTituloNotificacao('');
    setTextoNotificacao('');
    setTecnicosSelecionados([]);
  };

  const selectRef = useRef(null);
  const handleCloseVisualizar = () => setOpenVisualizar(false);

  const handleEnviarNotificacao = () => {
    if (!tituloNotificacao.trim()) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Por favor, preencha o título da notificação.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }
  
    if (!textoNotificacao.trim()) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Por favor, preencha a mensagem da notificação.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }
  
    if (!editandoNotificacaoId && (!Array.isArray(tecnicosSelecionados) || tecnicosSelecionados.length === 0)) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Por favor, selecione ao menos um técnico.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }
  
    if (editandoNotificacaoId) {
      handleSalvarEdicao();
    } else {
      setDialogConfirm(true);
    }
  };

  const handleConfirmarEnvio = async () => {
    setCarregandoEnvioNotificacoes(true);
    if (submeterNotificacao) return;
    setSubmeterNotificacao(true);

    if (!tituloNotificacao.trim() || !textoNotificacao.trim() || tecnicosSelecionados.length === 0) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Por favor, preencha o título, o texto da notificação e selecione ao menos um técnico.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      setSubmeterNotificacao(false);
      setCarregandoEnvioNotificacoes(false);
      return;
    }

    try {
      const novaNotificacao = {
        tituloNotificacao: tituloNotificacao.trim(),
        textoNotificacao: textoNotificacao.trim(),
        remetenteNotificacaoId: authService.getUserInfo().usuario.id,
        destinatarioNotificacaoId: tecnicosSelecionados,
      };

      const response = await notificacaoService.enviarNotificacao(novaNotificacao);

      if (response.error) {
        throw new Error(response.message || 'Ocorreu um erro ao enviar a notificação.');
      }

      setMensagemAlert({
        title: 'Sucesso',
        body: 'Notificação enviada com sucesso!',
      });
      setTipoAlert('success');
      setAbrirAlert(true);

      setDialogConfirm(false);
      handleClose();
      setTituloNotificacao('');
      setTextoNotificacao('');
      setTecnicosSelecionados([]);
      setCarregandoEnvioNotificacoes(true);
      buscarNotificacoes(page, pageSize, dataInicial, dataFinal)
    } catch (error) {
      setMensagemAlert({
        title: 'Erro',
        body: error.message || 'Ocorreu um erro ao enviar a notificação. Tente novamente.',
      });
      setTipoAlert('error');
      setAbrirAlert(true);
      setCarregandoEnvioNotificacoes(false);
    } finally {
      setSubmeterNotificacao(false);
      setCarregandoEnvioNotificacoes(false);
    }
  };

  const handleCancelarEnvio = () => {
    setDialogConfirm(false);
  };

  const handleExcluirNotificacao = (notificacao) => {
    setNotificacaoExcluir(notificacao);
    setDialogExcluir(true);
  };

  const handleEditarNotificacao = (notificacao) => {
    setTituloNotificacao(notificacao.titulo);
    setTextoNotificacao(notificacao.texto);
    setTecnicosSelecionados(notificacao.destinatarios); 
    setEditandoNotificacaoId(notificacao.lote);
  
    setOriginalTitulo(notificacao.titulo);
    setOriginalTexto(notificacao.texto);
  
    setOpen(true);
  };

  const handleSalvarEdicao = async () => {
    if (tituloNotificacao === originalTitulo && textoNotificacao === originalTexto) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Por favor, modifique pelo menos o título ou o texto antes de salvar.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }
  
    if (!tituloNotificacao || !textoNotificacao) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Preencha todos os campos obrigatórios.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }
  
    setCarregandoEnvioNotificacoes(true);
  
    const notificacaoAtualizada = {
      tituloNotificacao: tituloNotificacao,
      textoNotificacao: textoNotificacao,
    };
  
    try {
      const response = await notificacaoService.atualizarNotificacao(editandoNotificacaoId, notificacaoAtualizada);
  
      if (response.error) {
        if (response.message.includes('já foram lidas')) {
          setMensagemAlert({
            title: 'Atenção!',
            body: 'Esta notificação já foi visualizada e não pode ser atualizada.',
          });
          setTipoAlert('fail');
          setAbrirAlert(true);
        } else {
          setMensagemAlert({
            title: 'Erro',
            body: response.message || 'Erro ao atualizar a notificação.',
          });
          setTipoAlert('error');
          setAbrirAlert(true);
        }
      } else {
        setMensagemAlert({
          title: 'Sucesso',
          body: 'Notificação atualizada com sucesso!',
        });
        setTipoAlert('success');
        setAbrirAlert(true);
        handleClose();
        buscarNotificacoes(page, pageSize, dataInicial, dataFinal);
      }
    } catch (error) {
      setMensagemAlert({
        title: 'Erro',
        body: 'Erro inesperado ao atualizar a notificação. Tente novamente.',
      });
      setTipoAlert('error');
      setAbrirAlert(true);
    } finally {
      setCarregandoEnvioNotificacoes(false);
    }
  };

  const buscarNotificacoes = async (page, pageSize, dataInicial, dataFinal) => {
    setCarregandoTable(true);
  
    try {
      const dados = {
        pagina: page || 0,
        limite: pageSize || 8,
        dataInicial: dataInicial || '',
        dataFinal: dataFinal || ''
      };
  
      const response = await notificacaoService.listarNotificacoesVisaoGestorPaginado(authService.getUserInfo().usuario.id, dados);
  
      if (response.dados && response.dados.length > 0) {
        setTotalDados(response.total);
        setDadosNotificacoes(response.dados); 
      } else {
        setDadosNotificacoes([]);
        setTotalDados(0);
      }
    } catch (error) {
      setDadosNotificacoes([]);
    } finally {
      setCarregandoTable(false);
    }
  };

  const handleConfirmarExcluirNotificacao = async () => {
    setDialogExcluir(false);  
  
    try {
      const response = await notificacaoService.deletarNotificacao(notificacaoExcluir.lote);
      if (response.error) {
        const errorMessage = response.message === 'Nenhum dado encontrado.'
          ? 'A notificação já foi visualizada e não pode ser deletada.'
          : response.message || 'Erro ao deletar a notificação.';
  
        setMensagemAlert({
          title: 'Atenção!',
          body: errorMessage,
        });
        setTipoAlert('fail');
        setAbrirAlert(true);
      } else {
        setMensagemAlert({
          title: 'Sucesso',
          body: 'Notificação deletada com sucesso!',
        });
        setTipoAlert('success');
        setAbrirAlert(true);
  
        setDadosNotificacoes(prevDadosNotificacoes =>
          prevDadosNotificacoes.filter((notificacao) => notificacao.lote !== notificacaoExcluir.lote)
        );
      }
    } catch (error) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'A notificação já foi visualizada e não pode ser deletada.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
    }
  };

  useEffect(() => {
    async function fetchTecnicos() {
      setLoading(true);
      buscarNotificacoes(page, pageSize, dataInicial, dataFinal);
      setPermissaoGestor(authService.getUserInfo().usuario.gestor);
      const response = await auditoriaService.listarEmpresasGestor();
      if (response && !response.error) {
        setTecnicos(response);
      }
      setLoading(false);
    }

    fetchTecnicos();
  }, []);

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <MenuComponent />
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />

      <>
        {permissaoGestor == true ? (
          <>
            <div style={{ margin: 30 }}>
              <h3 className={classes.tituloStyle}>Central de Notificações</h3>
              <h4 className={classes.subtituloStyle}>
                <span
                  onClick={() => {
                    const userInfo = authService.getUserInfo();
                    if (userInfo.usuario.gestor) {
                      window.location.assign('/gestor');
                    } else {
                      window.location.assign('/agenda');
                    }
                  }}
                  className={classes.linkStyle}
                  style={{ cursor: 'pointer' }}
                >
                  Home
                </span>{' '}
                {'>'} Central de Notificações
              </h4>

              <Grid container spacing={2} alignItems="center" style={{ marginBottom: 20 }}>
                <Grid item xs={12} sm={3}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      id="start-date"
                      label="Data inicial"
                      type="date"
                      value={dataInicial}
                      onChange={(e) => setDataInicial(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      id="end-date"
                      label="Data final"
                      type="date"
                      value={dataFinal}
                      onChange={(e) => setDataFinal(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <BlueButton
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={aplicarFiltroDeDatas}
                    fullWidth
                    startIcon={carregandoBotaoPesquisar ? <CircularProgress color="primary" size={20} /> : <SearchIcon />}
                    disabled={carregandoBotaoPesquisar}
                    style={{ height: 55 }}
                  >
                    <span style={{ fontSize: '0.7rem' }}>{carregandoBotaoPesquisar ? ' BUSCANDO...' : ' BUSCAR'}</span>
                  </BlueButton>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <BlueButton
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleLimpar}
                    fullWidth
                    startIcon={carregandoBotaoLimpar ? <CircularProgress color="primary" size={20} /> : <ClearIcon />}
                    disabled={carregandoBotaoLimpar}
                    style={{ height: 55 }}
                  >
                    <span style={{ fontSize: '0.7rem' }}>{carregandoBotaoLimpar ? ' LIMPANDO...' : ' LIMPAR'}</span>
                  </BlueButton>
                </Grid>
              </Grid>

              <Paper className={classes.paper}>
                <Grid container spacing={1} direction="column">
                  <Grid container justifyContent="flex-end" item xs={12} sm={12} style={{ marginBottom: 15 }}>
                    <BlueButton
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{ height: 55, marginTop: mobile ? 10 : 0, width: mobile ? '100%' : '20%' }}
                      fullWidth
                      onClick={handleOpen}
                      startIcon={<NotificationAddIcon />}
                    >
                      Nova Notificação
                    </BlueButton>
                  </Grid>
                </Grid>

                {carregandoTable ? (
                  <Grid container justifyContent="center" alignItems="center" style={{ height: '400px' }}>
                    <CircularProgress />
                  </Grid>
                ) : dadosNotificacoes.length === 0 ? (
                  <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="subtitle1" color="textSecondary">
                      Nenhuma notificação encontrada.
                    </Typography>
                  </Grid>
                ) : (
                  <Grid container spacing={1} alignItems="stretch">
                  {dadosNotificacoes.map((notificacao) => (
                    <Grid item key={notificacao.lote} xs={12} md={3} style={{ width: '100%', maxWidth: '100%' }}>
                      <Paper
                        style={{
                          padding: '16px',
                          flexDirection: 'column',
                          height: '100%',
                          boxSizing: 'border-box',
                        }}
                      >
                        <Box padding={2} style={{ flexGrow: 1 }}>
                          <Typography component="div" variant="body2">
                            <strong>Título: </strong>
                            {notificacao.titulo.length > 20 ? `${notificacao.titulo.substring(0, 20)}...` : notificacao.titulo}
                          </Typography>
                          <Typography component="div" variant="body2">
                            <strong>Texto: </strong>
                            {notificacao.texto.length > 25 ? `${notificacao.texto.substring(0, 20)}...` : notificacao.texto}
                          </Typography>
                          <Typography component="div" variant="body2">
                            <strong>Data de Cadastro: </strong>{notificacao.dataCadastro ? new Date(notificacao.dataCadastro).toLocaleDateString() : ''}
                          </Typography>
                        </Box>

                        <Accordion
                          expanded={expandedId === notificacao.lote}
                          onChange={() => expandirOpcoes(notificacao.lote)}
                          style={{ marginTop: 10 }}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Notificação</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={12}>
                                <BlueButton
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  fullWidth
                                  style={{ height: 55 }}
                                  onClick={() => abrirConteudoLote(notificacao)}
                                  startIcon={<MessageIcon />}
                                >
                                  <span style={{ fontSize: '0.7rem' }}>Notificação</span>
                                </BlueButton>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <BlueButton
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  fullWidth
                                  style={{ height: 55 }}
                                  onClick={() => abrirModalListagemTecnicosLote(notificacao.lote)}
                                  disabled={carregandoTecnicosLoteNotificacoes && loteSelecionado === notificacao.lote}
                                  startIcon={carregandoTecnicosLoteNotificacoes && loteSelecionado === notificacao.lote ? <CircularProgress color="primary" size={20} /> : <EngineeringIcon />}
                                >
                                  <span style={{ fontSize: '0.7rem' }}>{carregandoTecnicosLoteNotificacoes && loteSelecionado === notificacao.lote ? 'Carregando...' : 'Técnicos'}</span>
                                </BlueButton>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <BlueButton
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  fullWidth
                                  style={{ height: 55 }}
                                  onClick={() => handleEditarNotificacao(notificacao)}  
                                  startIcon={<EditIcon />}
                                >
                                  <span style={{ fontSize: '0.7rem' }}>Editar</span>
                                </BlueButton>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <BlueButton
                                  variant="contained"
                                  color="secondary"
                                  size="large"
                                  fullWidth
                                  style={{ height: 55 }}
                                  onClick={() => handleExcluirNotificacao(notificacao)}
                                  startIcon={<DeleteIcon />}
                                >
                                  <span style={{ fontSize: '0.7rem' }}>Excluir</span>
                                </BlueButton>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Paper>
                    </Grid>
                  ))}
                  </Grid>
                )}

                <Dialog
                  open={dialogExcluir}
                  aria-labelledby="customized-dialog-title"
                  fullWidth
                  style={{ marginTop: 35, marginBottom: 10 }}
                  maxWidth="sm"
                  onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                      setDialogExcluir(false);
                    }
                  }}
                >
                  <DialogTitle>
                    <Grid container alignItems="center">
                      <Grid item xs={10} sm={11}>
                        Confirmação
                      </Grid>
                      <Grid item xs={2} sm={1}>
                        <IconButton onClick={() => setDialogExcluir(false)}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </DialogTitle>
                  <DialogContent dividers>

                  <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'start', marginBottom: '16px' }}>
                    Você tem certeza que deseja excluir esta notificação?
                  </Typography>
                  
                  <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'start' }}>
                    <strong>OBS:</strong> A solicitação uma vez enviada não poderá ser cancelada.
                  </Typography>

                  </DialogContent>
                  <DialogActions style={{ justifyContent: 'space-around' }}>
                    <Button 
                    fullWidth
                    onClick={() => setDialogExcluir(false)} 
                    variant="contained"
                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%' }}
                    >
                      Cancelar
                    </Button>
                    <BlueButton
                      onClick={handleConfirmarExcluirNotificacao}
                      style={{ marginBottom: '10px', marginTop: '10px', width: '40%' }}
                      variant="contained"
                      color="secondary"
                    >
                      Confirmar
                  </BlueButton>
                  </DialogActions>
                </Dialog>
                <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                  <Grid item>
                    {dadosNotificacoes.length > 0 && (
                      <CustomTablePagination
                        count={totalDados}
                        page={page}
                        rowsPerPage={pageSize}
                        onPageChange={mudarPagina}
                        quantidadeItens={totalDados}
                        onRowsPerPageChange={mudarTamanhoPagina}
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>

              <Dialog
                open={openVisualizar}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    handleCloseVisualizar()
                  }
                }}
                aria-labelledby="view-dialog-title"
                style={{ marginTop: 45, marginBottom: 10 }}
                fullWidth
                maxWidth="md"
              >
                <DialogTitle id="view-dialog-title">
                  <Grid container alignItems="center">
                    <Grid item xs={10} sm={11}>
                      Técnicos que receberam a notificação
                    </Grid>
                    <Grid item xs={2} sm={1}>
                      <IconButton onClick={handleCloseVisualizar}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent dividers>
                  {carregandoDadosTecnicosLoteNotificacoes ? (
                    <div className={classes.loadingContainer}>
                      <CircularProgress />
                    </div>
                  ) : tecnicosVisualizar.length === 0 ? (
                    <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 10 }}>
                      <Typography variant="subtitle1" color="textSecondary">
                        Nenhum registro encontrado.
                      </Typography>
                    </Grid>
                  ) : mobile ? (
                    <Grid container spacing={1}>
                      {tecnicosVisualizar.map((item, index) => (
                        <Grid item xs={12} key={index}>
                           <Paper
                          style={{
                            padding: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            minHeight: 100,
                            height: '100%',
                            boxSizing: 'border-box',
                          }}
                        >
                            <Typography component="div" variant="body2">
                              {item.tecnicoNome}
                            </Typography>
                            <Typography component="div" variant="body2">
                              {item.noticacaoLida
                                ? `Visualizado em ${new Date(
                                  item.dataNotificacaoLida
                                ).toLocaleDateString()} às ${new Date(
                                  item.dataNotificacaoLida
                                ).toLocaleTimeString()}`
                                : 'Não Visualizado'}
                            </Typography>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Box style={{ flex: 1, overflowY: 'auto' }}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography style={{ fontWeight: 'bold' }}>Técnico</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography style={{ fontWeight: 'bold' }}>Status</Typography>
                        </Grid>
                      </Grid>
                      {tecnicosVisualizar.map((item, index) => (
                        <Grid container key={index} style={{ padding: '8px 0' }} wrap="nowrap">
                          <Grid item xs={6}>
                            <Typography component="div" variant="body2">
                              {item.tecnicoNome}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography component="div" variant="body2">
                              {item.noticacaoLida
                                ? `Visualizado em ${new Date(
                                  item.dataNotificacaoLida
                                ).toLocaleDateString()} às ${new Date(
                                  item.dataNotificacaoLida
                                ).toLocaleTimeString()}`
                                : 'Não Visualizado'}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </Box>
                  )}
                  <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 15 }}>
                    <Grid item>
                      {tecnicosVisualizar.length > 0 && (
                        <CustomTablePagination
                          count={tecnicosVisualizar.length}
                          page={paginaLoteTecnicos}
                          rowsPerPage={tamanhoPaginaLoteTecnicos}
                          onPageChange={mudarPaginaLoteTecnicos}
                          quantidadeItens={tecnicosVisualizar.length || 0}
                          onRowsPerPageChange={mudarTamanhoLoteTecnicos}
                        />
                      )}
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleCloseVisualizar}
                    variant="contained"
                    style={{ width: '40%', height: 55 }}>
                    <span style={{ fontSize: '0.7rem' }}>Fechar</span>
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={open}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    handleClose
                  }
                }}
                aria-labelledby="form-dialog-title"
                style={{ marginTop: 35, marginBottom: 10 }}
                fullWidth
                maxWidth="md"
              >
                <DialogTitle>
                  <Grid container alignItems="center">
                    <Grid item xs={10} sm={11}>
                      {editandoNotificacaoId ? 'Editar Notificação' : 'Cadastrar Notificação'}
                    </Grid>
                    <Grid item xs={2} sm={1}>
                      <IconButton onClick={handleClose}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <TextField
                      label={<span>Título da Notificação <span style={{ color: 'red' }}> *</span></span>}
                      variant="outlined"
                      fullWidth
                      value={tituloNotificacao}
                      onChange={(e) => setTituloNotificacao(e.target.value)}
                      style={{ marginBottom: 16 }}
                    />
                    <TextField
                      label={<span>Texto da Notificação <span style={{ color: 'red' }}> *</span></span>}
                      variant="outlined"
                      multiline
                      rows={4}
                      fullWidth
                      value={textoNotificacao}
                      onChange={(e) => setTextoNotificacao(e.target.value)}
                      style={{ marginBottom: 16 }}
                    />

                      {!editandoNotificacaoId && (
                        <div>
                        <Grid container spacing={1} style={{ justifyContent: 'space-around' }}>
                          <Grid item xs={12} sm={12}>
                            <BlueButton 
                              variant="contained"
                              color="primary"
                              size="large"
                              fullWidth
                              onClick={() => setIsModalOpen(true)}
                              >
                              Abrir Seleção de Técnicos
                            </BlueButton>
                          </Grid>
                          <Grid item xs={1} sm={8}>
                          </Grid>
                          <Grid item xs={11} sm={4}>
                          <span style={{ marginLeft: '10px', fontSize: '1rem' }}>
                            {tecnicosSelecionados.length} Técnico(s) Selecionado(s)
                          </span>
                          </Grid>
                        </Grid>

                        <Modal
                          open={isModalOpen}

                          onClose={() => setIsModalOpen(false)}
                          aria-labelledby="select-tecnicos-modal"
                          aria-describedby="popup-para-selecionar-tecnicos"
     >
                                  <Box
                                    sx={{
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      transform: 'translate(-50%, -50%)',
                                      width: { xs: '90%', sm: '50%' },
                                      bgcolor: 'background.paper',
                                      boxShadow: 24,
                                      borderRadius: '8px',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      height: { xs: '75%', sm: '45%' },
                                      overflow: 'hidden',
                                    }}
                                  >
                                <h3 style={{ margin: '16px' }}>Selecionar Técnicos</h3>

                                <div style={{ flex: 1, overflowY: 'auto', padding: '0 16px' }}>
                                  <Select
                                    isMulti
                                    options={[allOption, ...options]}
                                    value={options.filter((option) =>
                                      tecnicosSelecionados.includes(option.value) || option.value === 'all'
                                    )}
                                    placeholder="Selecionar Técnicos" 
                                    onChange={handleChange}
                                    closeMenuOnSelect={false}
                                    styles={customStyles}
                                    getOptionLabel={(e) =>
                                      e.value === 'all' ? (
                                        <>
                                          <Checkbox
                                            checked={tecnicosSelecionados.length === tecnicos.length}
                                            indeterminate={
                                              tecnicosSelecionados.length > 0 && tecnicosSelecionados.length < tecnicos.length
                                            }
                                          />
                                          {e.label}
                                        </>
                                      ) : (
                                        <>
                                          <Checkbox
                                            checked={tecnicosSelecionados.includes(e.value)}
                                          />
                                          {e.label}
                                        </>
                                      )
                                    }
                                  />
                                </div>

                                <div
                                  style={{
                                    borderTop: '1px solid #ccc',
                                    padding: '16px',
                                    textAlign: 'right',
                                    position: 'sticky',
                                    bottom: 0,
                                    backgroundColor: 'white',
                                  }}
                                >
                                  <BlueButton 
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  fullWidth
                                  onClick={() => setIsModalOpen(false)}>
                                    Selecionar Técnicos
                                  </BlueButton>
                                </div>
                              </Box>
                            </Modal>
                          </div>
                        )}
                  </DialogContent>

                <DialogActions style={{ justifyContent: 'space-around' }}>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    disabled={submeterNotificacao}
                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}>
                    <span style={{ fontSize: '0.7rem' }}>Cancelar</span>
                  </Button>
                  <BlueButton
                    onClick={handleEnviarNotificacao}
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                    disabled={submeterNotificacao}
                  >
                    {submeterNotificacao ? (
                      <CircularProgress size={24} color="secondary" />
                    ) : (
                      <span style={{ fontSize: '0.7rem' }}>
                        {editandoNotificacaoId ? 'Salvar Alterações' : 'Enviar Notificação'}
                      </span>
                    )}
                  </BlueButton>
                </DialogActions>
              </Dialog>

              <Dialog
                open={dialogConfirm}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    handleCancelarEnvio();
                  }
                }}
                style={{ marginTop: 35, marginBottom: 10 }}
                fullWidth
              >
                <DialogTitle>
                  <Grid container alignItems="center">
                    <Grid item xs={10} sm={11}>
                      Confirmação de Envio
                    </Grid>
                    <Grid item xs={2} sm={1}>
                      <IconButton onClick={handleCancelarEnvio}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent dividers>
                  <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'start', marginBottom: '16px' }}>
                    Tem certeza de que deseja {editandoNotificacaoId ? 'salvar as alterações' : 'enviar esta notificação'}?
                  </Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-around' }}>
                  <Button
                    onClick={handleCancelarEnvio}
                    variant="contained"
                    disabled={carregandoEnvioNotificacoes}
                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}>
                    <span style={{ fontSize: '0.7rem' }}>Cancelar</span>
                  </Button>
                  <BlueButton
                    onClick={handleConfirmarEnvio}
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={carregandoEnvioNotificacoes}
                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                    startIcon={carregandoEnvioNotificacoes ? <CircularProgress size={20} /> : ''}
                  >
                    <span style={{ fontSize: '0.7rem' }}> {carregandoEnvioNotificacoes ? ' Enviando...' : ' Sim'}</span>
                  </BlueButton>
                </DialogActions>
              </Dialog>

              <Dialog
                aria-labelledby="customized-dialog-title"
                open={modalConteudo}
                className="custom-dialog"
                style={{ marginTop: 35, marginBottom: 10 }}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    fecharConteudoLote();
                  }
                }}
                fullWidth
                maxWidth="md"
              >
                <DialogTitle>
                  <Grid container alignItems="center">
                    <Grid item xs={10} sm={11}>
                      Descrição da Notificação
                    </Grid>
                    <Grid item xs={2} sm={1}>
                      <IconButton onClick={fecharConteudoLote}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent dividers>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'start', marginBottom: '16px' }}>
                      Título: {loteSelecionado?.titulo}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      style={{
                        textAlign: 'justify',
                        wordBreak: 'break-word',
                        whiteSpace: 'normal',
                      }}
                    >
                      Texto: {loteSelecionado?.texto}
                    </Typography>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => setModalConteudo(false)}
                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                  >
                    Fechar
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </>
        ) : (
          <div style={{ margin: 30 }}>
            <h3 className={classes.tituloStyle}>Central de Notificações</h3>
            <h4 className={classes.subtituloStyle}>
              <span
                onClick={() => window.location.href = '/agenda'}
                className={classes.linkStyle}
                style={{ cursor: 'pointer' }}
              >
                Home
              </span> {'>'} Central de Notificações
            </h4>

            <Paper className={classes.paper}>
              <Grid container justifyContent="center">
                <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>Você não tem permissão para acessar esta página!</h2>
              </Grid>
            </Paper>
          </div>
        )}
      </>
    </>
  );
};

export default NotificacoesGestor;
