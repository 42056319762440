import React, { useState, useEffect } from 'react';
import gestorService from '../Services/gestor/gestor-service';
import AtividadeDialog from './atividade/atividade-dialog';
import {
  Grid,
  Typography,
  Paper,
  Tooltip,
  LinearProgress,
  Box,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { TextField, CircularProgress, FormControl } from '@material-ui/core';
import './TabelaTecnicos.css';
import { Close as CloseIcon } from '@material-ui/icons';
import BlueButton from '../components/button/BlueButton';
import SearchIcon from '@material-ui/icons/Search';
import CustomAlert from '../components/alert/CustomAlert';
import auditoriaService from '../Services/Auditoria/auditoria-service';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import CustomTablePagination from '../components/pagination/pagination-component';
import ClearIcon from '@material-ui/icons/Clear';

const statusColors = {
  inProgress: 'blue',
  finished: 'green',
  notCheckin: '#6495ED',
  notCheckout: 'red',
  timeAlert: 'yellow',
  checkinOutOfTime: '#98FB98',
  default: 'default',
};

const statusMessages = {
  finished: 'Técnico finalizou a atividade',
  inProgress: 'Atividade em andamento',
  notCheckin: 'Técnico não fez checkin',
  notCheckout: 'Técnico não fez checkout',
  timeAlert: 'Prazo de checkout está chegando',
  checkinOutOfTime: 'Técnico realizou o checkin fora do prazo',
  default: '',
};


export default function TabelaTecnicos() {
  const [atividadesTecnicos, setAtividadesTecnicos] = useState([]);
  const [selectedAtividade, setSelectedAtividade] = useState(null);
  const [openDetalhesAtividade, setOpenDetalhesAtividade] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const theme = useTheme();
  const [tecnicos, setTecnicos] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [tipoAlert, setTipoAlert] = useState('error');
  const [tecnicoSelecionado, setTecnicoSelecionado] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [totalDados, setTotalDados] = useState(0);
  const [buscaRealizada, setBuscaRealizada] = useState(false);

  useEffect(() => {
    atividades('', '', 0, pageSize);
    usuarios();
  }, []);

  const atividades = async (startDate, endDate, page, pageSize, tecnicoSelecionado) => {
    setLoading(true);
    if (startDate && endDate && moment(startDate).isAfter(endDate)) {
      setMensagemAlert({ title: 'Atenção!', body: 'Data inicial não pode ser maior que a data final!' });
      setTipoAlert('fail');
      setAbrirAlert(true);
      setStartDate('');
      setEndDate('');

      setLoading(false);
      return;
    }

    const dados = {
      dataInicial: startDate ? startDate : '',
      dataFinal: endDate ? endDate : '',
      pagina: page ? page : 0,
      limite: pageSize ? pageSize : 5,
      tecnicoNome: tecnicoSelecionado ? tecnicoSelecionado : '',
    }

    const listaAtividades = await gestorService.listaAtividadesTecnicos(dados);

    if (listaAtividades.dados) {
      let dadosAtividades = listaAtividades.dados;
      const atividadesPorTecnico = {};

      dadosAtividades.forEach((a) => {
        const tecnicoNome = a.tecnicoNome || '';
        if (!atividadesPorTecnico[tecnicoNome]) {
          atividadesPorTecnico[tecnicoNome] = [];
        }

        if (a.previsaoInicio) {
          var dataInicioAtual = new Date(a.previsaoInicio);
          dataInicioAtual.setHours(dataInicioAtual.getHours() - 3);
          dataInicioAtual.setTime(dataInicioAtual.getTime() - 180 * 60 * 1000);
          var novaPrevisaoInicio = dataInicioAtual.toISOString().slice(0, 19);
        }
        if (a.previsaoTermino) {
          var dataTerminoAtual = new Date(a.previsaoTermino);
          dataTerminoAtual.setHours(dataTerminoAtual.getHours() - 3);
          dataTerminoAtual.setTime(dataTerminoAtual.getTime() - 180 * 60 * 1000);
          var novaPrevisaoTermino = dataTerminoAtual.toISOString().slice(0, 19);
        }

        atividadesPorTecnico[tecnicoNome].push({
          id: a.atividadeID,
          tecnicoNome: a.tecnicoNome,
          checkin: a.inicio || '',
          checkout: a.termino || '',
          prazoCheckin: novaPrevisaoInicio || '',
          prazoCheckout: novaPrevisaoTermino || '',
          clienteNome: a.clienteNome,
          na: a.atividadeNumero
        });
      });

      const atividadesTransformadas = Object.keys(atividadesPorTecnico).map(
        (nome) => ({
          nome,
          atividades: atividadesPorTecnico[nome],
        }),
      );

      setAtividadesTecnicos(atividadesTransformadas);
      setTotalDados(listaAtividades.total);
    }
    setLoading(false);
  }

  const usuarios = async () => {
    const response = await auditoriaService.listarEmpresasGestor();
    if (!response.error) {
      setTecnicos(response);
    }
  }

  const realizarBusca = () => {
    if (!startDate || !endDate) {
      setMensagemAlert({ title: 'Atenção!', body: 'Por favor, selecione ambas as datas!' });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }

    if (moment(startDate).isAfter(endDate)) {
      setMensagemAlert({ title: 'Atenção!', body: 'A data inicial não pode ser maior que a data final!' });
      setTipoAlert('fail');
      setAbrirAlert(true);
      setStartDate('');
      setEndDate('');
      return;
    }

    setPage(0);
    atividades(startDate, endDate, 0, pageSize, tecnicoSelecionado);
    setBuscaRealizada(true);
  };

  const limparDados = async () => {
    setPage(0);
    setPageSize(5);
    setEndDate('');
    setStartDate('');
    setTecnicoSelecionado('');
    atividades('', '', 0, pageSize, '');
    setBuscaRealizada(false);
  };

  const hours = Array.from({ length: 11 }, (_, i) => i + 8);

  const isHourInRange = (hour, startTime, endTime) => {
    const startHour = new Date(startTime).getHours();
    const endHour = new Date(endTime).getHours();
    return hour >= startHour && hour <= endHour;
  };

  const getDisplayedHours = (activeHours) => {
    const totalHours = Math.max(activeHours.length, 6);
    const emptySlots = totalHours - activeHours.length;

    const before = Math.floor(emptySlots / 2);
    const after = emptySlots - before;

    const firstActiveHour = activeHours[0] || 8;
    const lastActiveHour = activeHours[activeHours.length - 1] || 8;

    const beforeHours = Array.from({ length: before }, (_, i) => firstActiveHour - (before - i));
    const afterHours = Array.from({ length: after }, (_, i) => lastActiveHour + (i + 1));

    return [...beforeHours, ...activeHours, ...afterHours];
  };

  const getCellStatus = (atividade, hour) => {
    const checkinTime = new Date();
    const prazoCheckinTime = new Date();

    const timeCheckin = atividade.checkin.split('T')[1];
    const timePrazoCheckin = atividade.prazoCheckin.split('T')[1];

    if (!isHourInRange(hour, atividade.checkin, atividade.checkout) && !isHourInRange(hour, atividade.prazoCheckin, atividade.prazoCheckout)) {
      return 'default';
    }

    const isCheckinEmpty = atividade.checkin === '';
    if (isCheckinEmpty) {
      return 'notCheckin';
    }

    checkinTime.setHours(
      parseInt(timeCheckin.split(':')[0]),
      parseInt(timeCheckin.split(':')[1]),
    );
    prazoCheckinTime.setHours(
      parseInt(timePrazoCheckin.split(':')[0]),
      parseInt(timePrazoCheckin.split(':')[1]),
    );

    const isCheckinTimeOutOfRange =
      Math.abs(checkinTime - prazoCheckinTime) > 30 * 60 * 1000;

    if (atividade.checkin && atividade.checkout) {
      if (isCheckinTimeOutOfRange) {
        return 'checkinOutOfTime';
      } else if (isHourInRange(hour, atividade.checkin, atividade.checkout)) {
        return 'finished';
      }
    } else {
      if (!atividade.checkin) {
        return isHourInRange(hour, atividade.prazoCheckin, atividade.prazoCheckout) ? 'notCheckin' : 'default';
      }
      if (!atividade.checkout) {
        if (isCheckinTimeOutOfRange) {
          return 'checkinOutOfTime';
        }
        if (isHourInRange(hour, atividade.checkin, atividade.prazoCheckout)) {
          return 'inProgress';
        }
      }
    }

    if (isHourInRange(hour, atividade.checkin, atividade.prazoCheckout)) {
      return 'inProgress';
    }

    return 'default';
  };

  const formatTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return `${date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;
  };

  const LegendaStatus = ({ atividadesTecnicos }) => {
    const statusPresentes = new Set();

    if (atividadesTecnicos && Array.isArray(atividadesTecnicos)) {
      atividadesTecnicos.forEach((tecnico) => {
        if (tecnico.atividades && Array.isArray(tecnico.atividades)) {
          tecnico.atividades.forEach((atividade) => {
            hours.forEach((hour) => {
              const status = getCellStatus(atividade, hour);
              if (status !== 'default') {
                statusPresentes.add(status);
              }
            });
          });
        }
      });
    }

    if (statusPresentes.size === 0) {
      return null;
    }

    return (
      <Box mb={2} display="flex" justifyContent={isMobile ? 'center' : 'flex-end'} width="100%">
        <Paper
          elevation={3}
          style={{
            padding: '10px',
            display: 'flex',
            alignItems: isMobile ? 'flex-start' : 'center',
            flexDirection: isMobile ? 'column' : 'row',
            textAlign: isMobile ? 'center' : 'left',
            width: '100%', 
            maxWidth: '100%'
          }}
        >
          <Typography variant="subtitle1" style={{ marginRight: isMobile ? 0 : 16, marginBottom: isMobile ? 8 : 0 }}>
            Legenda:
          </Typography>
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'flex-start' : 'center'}
            width="100%"  
          >
            {Array.from(statusPresentes).map((status) => (
              <Box
                display="flex"
                alignItems="center"
                key={status}
                style={{ marginRight: isMobile ? 0 : 16, marginBottom: isMobile ? 8 : 0 }}
              >
                <Box
                  style={{
                    width: 12,
                    height: 12,
                    backgroundColor: statusColors[status],
                    borderRadius: '50%',
                    marginRight: 8
                  }}
                />
                <Typography variant="caption" style={{ color: '#000' }}>
                  {statusMessages[status]}
                </Typography>
              </Box>
            ))}
          </Box>
        </Paper>
      </Box>

    );
  };

  const getHourAndMinuteFromDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return { hour: date.getHours(), minute: date.getMinutes() };
  };

  const handleOpenDetalhesAtividade = (atividade, status) => {
    if (status !== '') {
      const atividadeComStatus = { ...atividade, status: status };
      setSelectedAtividade(atividadeComStatus);
      setOpenDetalhesAtividade(true);
    }
  };

  const handleCloseDetalhesAtividade = () => {
    setOpenDetalhesAtividade(false);
  };

  const mudarPagina = (event, newPage) => {
    setPage(newPage);
    atividades(startDate, endDate, newPage, pageSize, tecnicoSelecionado);
  };

  const mudarTamanhoPagina = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
    atividades(startDate, endDate, 0, parseInt(event.target.value, 10), tecnicoSelecionado);
  };

  return (
    <>
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />

      <Grid container spacing={2} alignItems="center" style={{ marginBottom: 20 }}>
        <Grid item xs={12} sm={3}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              id="start-date"
              label="Data inicial"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              id="end-date"
              label="Data final"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Autocomplete
            id="select-technician"
            options={tecnicos}
            getOptionLabel={(option) => option.nome}
            value={tecnicos.find((tecnico) => tecnico.nome === tecnicoSelecionado) || null}
            onChange={(event, newValue) => {
              setTecnicoSelecionado(newValue ? newValue.nome : null);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Técnico"
                variant="outlined"
                fullWidth
              />
            )}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <BlueButton
            variant="contained"
            color="primary"
            size="large"
            onClick={realizarBusca}
            fullWidth
            startIcon={<SearchIcon />}
            style={{ height: 55 }}
          >
            <span style={{ fontSize: '0.7rem' }}>BUSCAR</span>
          </BlueButton>
        </Grid>

        <Grid item xs={12} sm={2}>
          <BlueButton
            variant="contained"
            color="primary"
            size="large"
            onClick={limparDados}
            fullWidth
            startIcon={<CloseIcon />}
            style={{ height: 55 }}
          >
            <span style={{ fontSize: '0.7rem' }}>LIMPAR</span>
          </BlueButton>
        </Grid>
      </Grid>

      <LegendaStatus atividadesTecnicos={atividadesTecnicos} />

      <Grid container>
        {loading ? (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        ) : (
          atividadesTecnicos.length === 0 ? (
            <Grid container justifyContent="center" alignItems="center" item xs={12} style={{ margin: 10 }}>
              <Typography variant="body1">Nenhuma atividade foi encontrada.</Typography>
            </Grid>
          ) : (
            atividadesTecnicos.map((tecnico, index) => {
              const activeHours = hours.filter(hour =>
                tecnico.atividades.some(atividade =>
                  isHourInRange(hour, atividade.checkin, atividade.checkout) ||
                  isHourInRange(hour, atividade.prazoCheckin, atividade.prazoCheckout)
                )
              );

              const displayedHours = getDisplayedHours(activeHours);

              return (
                <Grid item xs={12} key={index}>
                  <Paper style={{ padding: '8px', marginBottom: '16px' }}>
                    <Typography variant="h6" gutterBottom className="tecnico-nome">
                      {tecnico.nome}
                    </Typography>

                    {tecnico.atividades.map((atividade, atividadeIndex) => (
                      <Paper
                        key={`${index}-${atividadeIndex}`}
                        style={{ backgroundColor: '#F8F8FF', padding: '8px', marginBottom: '16px' }}
                        onClick={() => {
                          const hour = displayedHours.find(hour => isHourInRange(hour, atividade.checkin, atividade.checkout) || isHourInRange(hour, atividade.prazoCheckin, atividade.prazoCheckout));
                          handleOpenDetalhesAtividade(atividade, statusMessages[getCellStatus(atividade, hour)]);
                        }}
                      >
                        <Typography variant="subtitle1" style={{ marginBottom: '8px' }}>
                          {atividade.clienteNome}
                        </Typography>
                        <Box
                          display={isMobile ? 'grid' : 'flex'}
                          gridTemplateColumns={isMobile ? 'repeat(3, 1fr)' : 'none'}
                          justifyContent="center"
                          flexWrap={isMobile ? 'nowrap' : 'wrap'}
                          width="100%"
                        >
                          {displayedHours.map((hour) => {
                            const checkin = atividade.checkin ? getHourAndMinuteFromDateTime(atividade.checkin) : null;
                            const checkout = atividade.checkout ? getHourAndMinuteFromDateTime(atividade.checkout) : null;
                            const displayTime = checkin && checkin.hour === hour
                              ? formatTime(atividade.checkin)
                              : checkout && checkout.hour === hour
                                ? formatTime(atividade.checkout)
                                : '';

                            return (
                              <Box key={hour} mx={1} mb={isMobile ? 1 : 0}>
                                <Tooltip title={statusMessages[getCellStatus(atividade, hour)]}>
                                  <Paper
                                    style={{
                                      backgroundColor: statusColors[getCellStatus(atividade, hour)],
                                      width: '55px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      padding: '8px',
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {displayTime || `${hour}:00`}
                                    </Typography>
                                  </Paper>
                                </Tooltip>
                              </Box>
                            );
                          })}
                        </Box>
                        <Grid container spacing={2} style={{ marginTop: '5px' }}>
                          <Grid item xs={6}>
                            <Paper style={{ backgroundColor: '#fff', padding: '8px', marginBottom: '16px' }}>
                              <Typography variant="body2" style={{ fontSize: '12px', textAlign: 'center' }}>
                                Prazo de Check-in:
                              </Typography>
                              <Typography variant="body2" style={{ fontSize: '12px', textAlign: 'center' }}>
                                {atividade.prazoCheckin ? formatTime(atividade.prazoCheckin) : 'Não definido'}
                              </Typography>
                            </Paper>
                          </Grid>
                          <Grid item xs={6}>
                            <Paper style={{ backgroundColor: '#fff', padding: '8px', marginBottom: '16px' }}>
                              <Typography variant="body2" style={{ fontSize: '12px', textAlign: 'center' }}>
                                Prazo de Check-out:
                              </Typography>
                              <Typography variant="body2" style={{ fontSize: '12px', textAlign: 'center' }}>
                                {atividade.prazoCheckout ? formatTime(atividade.prazoCheckout) : 'Não definido'}
                              </Typography>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Paper>
                    ))}

                  </Paper>
                </Grid>
              );
            })
          )
        )}
      </Grid>

      {atividadesTecnicos.length > 0 && (
        <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
          <Grid item>
            <CustomTablePagination
              count={totalDados}
              page={page}
              rowsPerPage={pageSize}
              onPageChange={mudarPagina}
              quantidadeItens={totalDados}
              onRowsPerPageChange={mudarTamanhoPagina}
            />
          </Grid>
        </Grid>
      )}

      <AtividadeDialog
        open={openDetalhesAtividade}
        handleClose={handleCloseDetalhesAtividade}
        atividade={selectedAtividade}
      />
    </>
  );
}
