import React, { useState } from 'react';
import { Container, TextField, Grid, FormControl, RadioGroup, FormControlLabel, Radio, CircularProgress } from '@material-ui/core';
import BlueButton from '../../components/button/BlueButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import contraSenhaService from '../../Services/ContraSenha/contra-senha-service';
import CustomAlert from '../../components/alert/CustomAlert';


export default function ContraSenhaComponent({ os }) {
  const [contraSenha, setContraSenha] = React.useState('');
  const [efeitoCarregando, setEfeitoCarregando] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ title: '', body: '' });
  const [alertType, setAlertType] = useState('success');

  const handleContraSenha = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    let opcao = data.get('rdOpcao');
    let ocorrencia = data.get('txtOcorrencia');

    if(ocorrencia === ''){
      setAlertMessage({ title: 'Atenção!', body: 'É obrigatório o preenchimento da ocorrência.' });
      setAlertType('fail');
      setAlertOpen(true);
      return;
    }

    setEfeitoCarregando(true);

    let contraSenhaResult = await contraSenhaService.contraSenha(opcao, ocorrencia, os.na);

    if (contraSenhaResult) {
      setContraSenha(contraSenhaResult.contraSenha);
      setAlertMessage({ title: 'Sucesso!', body: 'Contrassenha gerada com sucesso!' });
      setAlertType('success');
      setAlertOpen(true);
      setEfeitoCarregando(false);
    } else {
      setAlertMessage({ title: 'Erro!', body: 'Ocorrência não encontrada! Por favor válidar número da ocorrência.' });
      setAlertType('error');
      setAlertOpen(true);
      setEfeitoCarregando(false);
    }
  };

  return (
    <Container>
      <div>
        <form onSubmit={handleContraSenha} required>
          <Grid container style={{ flexGrow: 1 }} spacing={3}>
            <Grid xs={12} md={12} lg={5} item>
              <FormControl component="fieldset">
                <RadioGroup row aria-label="rdOpcao" name="rdOpcao" defaultValue="top">
                  <FormControlLabel
                    value="1"
                    control={<Radio required color="primary" />}
                    label="Modelo RI/Configuração"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio required color="primary" />}
                    label="Opções Avançadas"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid xs={12} md={12} lg={3} item>
              <TextField
                variant="outlined"
                name="txtOcorrencia"
                label={
                  <span>
                    Ocorrência <span style={{ color: 'red' }}>*</span>
                  </span>
                }
                placeholder="Ocorrência"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid xs={12} md={12} lg={3} item>
              <div>
                <BlueButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ marginBottom: '20px', height: 55 }}
                  startIcon={efeitoCarregando ? <CircularProgress size={20} /> : <RefreshIcon />}
                  disabled={efeitoCarregando}
                >
                  <span style={{ fontSize: '0.7rem' }}>
                    {efeitoCarregando ? 'Gerando... ' : 'Gerar'}
                  </span>
                </BlueButton>
              </div>
            </Grid>
          </Grid>
        </form>

        <TextField
          multiline
          name="txtContraSenha"
          value={contraSenha}
          fullWidth
          disabled
          InputLabelProps={{
            shrink: true,
          }}
        />

        <CustomAlert
          open={alertOpen}
          message={alertMessage}
          type={alertType}
          onClose={() => setAlertOpen(false)}
        />
      </div>
    </Container>
  );
}
